import _, { divide } from "lodash";
import moment from "moment";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { MgmtOfficeModel } from "src/api/building/building-types";
import { getBuildingManagementAsync } from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderDetailModel } from "src/api/provider/provider-types";
import { PageMeta } from "src/api/public-types";
import { getWorkOrderRevisionsAsync } from "src/api/work-order/workorder-api";
import { WorkOrderRevisionParams, WorkOrderRevisionsModel, WorkOrderStatus, WorkOrderType } from "src/api/work-order/workorder-types";
import { BaseTooltip } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import { YmdFormat, formatPhoneNumber } from "src/utils/common-util";

// Revision 정보
interface Revision {
  // key
  key: string;

  // No
  no: number;

  // 변경유형
  revisionTypeText: string;

  // 변경 영역
  localAttributes: Array<string>;

  // Changed Field
  attributes: Array<string>;

  // 수정 내용
  values: Array<string>;

  // 수정 일시
  revisionInstantText: string;

  // 수정자
  lastModifiedBy: string;
}

interface OfficeInfo {
  mgmtOfficeId?: string;
  building?: MgmtOfficeModel;
  provider?: ProviderDetailModel;
}

const WorkOrderHistory = () => {
  const params = useParams();
  const location = useLocation();

  const workOrderId = useMemo(() => params.id, [params]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [pageNum, setPageNum] = useState(0);

  // 변경내역
  const [originalRevisions, setOriginalRevisions] = useState<Array<WorkOrderRevisionsModel>>([]);

  // 프로바이더 정보들
  const [officeInfos, setOfficeInfos] = useState<Array<OfficeInfo>>([]);

  const queryParams: WorkOrderRevisionParams = useMemo(() => {
    const _queryParams: WorkOrderRevisionParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      //pagination 구현시 20으로 요청예정
      _queryParams.size = 9999;
    }
    if (!_queryParams.id) {
      _queryParams.id = Number(workOrderId ? workOrderId : Number(queryParams.id));
    }

    return _queryParams;
  }, [location.search, workOrderId]);

  //
  // 워크오더 변경내역 리스트
  const { executeAsync: getWorkOrderRevisions } = useApiOperation(getWorkOrderRevisionsAsync);

  // 관리처 관리 상세
  const { executeAsync: getBuildingManagement } = useApiOperation(getBuildingManagementAsync);

  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 워크오더 변경내역 가져오기
  useEffect(() => {
    const fetchWorkOrderRevisions = async (passParams: WorkOrderRevisionParams) => {
      const response = await getWorkOrderRevisions(passParams);

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;

        setOriginalRevisions(result);
        setPageMeta(response.data.meta.pageMeta);

        const mgmtOfficeInfo: { [props: string]: Promise<OfficeInfo> } = {};
        result.forEach((item) => {
          const mgmtOfficeId = item.entity?.mgmtOfficeId;

          if (!!mgmtOfficeId && !mgmtOfficeInfo[mgmtOfficeId]) {
            mgmtOfficeInfo[mgmtOfficeId] = new Promise<OfficeInfo>(async (resolve) => {
              const { data: buildingData } = await getBuildingManagement({ id: mgmtOfficeId });
              const providerId = buildingData?.data?.content?.providerId;

              if (!providerId) {
                resolve({});
                return;
              }

              const { data: providerData } = await getProviderDetail({
                providerId: providerId,
              });
              resolve({
                mgmtOfficeId,
                building: buildingData?.data?.content,
                provider: providerData?.data || {},
              });
            });
          }
        });
        const promises = Object.values(mgmtOfficeInfo);

        const officeInfos = await Promise.all(promises);
        setOfficeInfos(officeInfos);
      }
    };

    fetchWorkOrderRevisions(queryParams);
  }, [getWorkOrderRevisions, getBuildingManagement, getProviderDetail, queryParams]);

  // 화면에 표시할 revision 목록
  const revisions = useMemo<Array<Revision>>(() => {
    const revisions = originalRevisions
      .map((item, index, items) => {
        const { revisionType, revisionInstant, revisionNumber } = item.metaData || {};
        const { entity = {} } = item || {};
        const { modifiedBy = "" } = entity;
        const prev = { ...items[index - 1]?.entity };

        const revision: Revision = {
          key: revisionNumber || "",
          no: pageNum * 20 + 1 + index,
          revisionTypeText: "",
          localAttributes: [],
          attributes: [],
          values: [],
          revisionInstantText: moment(revisionInstant).format("YYYY.MM.DD HH:mm:ss"),
          lastModifiedBy: modifiedBy === "product" ? "모바일웹에서 변경됨" : modifiedBy,
        };
        /**
         * 변경유형
         */
        if (revisionType === "INSERT") {
          revision.revisionTypeText = "생성";
        } else if (revisionType === "UPDATE") {
          revision.revisionTypeText = "변경";
        } else if (revisionType === "DELETE") {
          revision.revisionTypeText = "삭제";
        }

        /**
         * 변경사항
         */
        if (_.isEmpty(prev)) {
          return revision;
        }

        const officeInfo = officeInfos.find((providerInfo) => providerInfo.mgmtOfficeId === item.entity?.mgmtOfficeId) || {};

        Object.entries(prev).forEach(([prevKey, prevValue]) => {
          if (!Object.hasOwn(entity, prevKey) || (entity as any)[prevKey] === prevValue) {
            return;
          }

          // attribute, value 설정
          let localAttribute = prevKey;
          let value = String((entity as any)[prevKey]);
          switch (prevKey) {
            case "startDate": {
              localAttribute = "시작일";
              value = moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM);
              break;
            }
            case "endDate": {
              localAttribute = "처리기한";
              value = moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM);
              break;
            }
            case "mgmtOfficeId": {
              localAttribute = "관리처";
              value = officeInfo?.provider?.provider?.providerName || value;

              break;
            }
            case "mgmtOfficerId": {
              localAttribute = "담당자";
              value = officeInfo?.building?.officerList?.find((officer) => String(officer.id) === value)?.name || value;

              break;
            }
            case "status": {
              localAttribute = "상태";

              value =
                value === WorkOrderStatus.OPEN
                  ? "접수"
                  : value === WorkOrderStatus.WAITING
                  ? "확인"
                  : value === WorkOrderStatus.PROGRESS
                  ? "작업중"
                  : value === WorkOrderStatus.COMPLETED
                  ? "완료"
                  : value === WorkOrderStatus.CLOSED
                  ? "종료"
                  : "취소";

              break;
            }
            case "summary": {
              localAttribute = "워크시트 제목";
              break;
            }
            case "description": {
              localAttribute = "워크시트 내용";
              break;
            }
            case "workOrderPriority": {
              localAttribute = "우선 순위";
              value = value === "1" ? "낮음" : value === "2" ? "보통" : value === "3" ? "높음" : "긴급";
              break;
            }
            case "imageChangeCount": {
              if (Number(value) < 1) {
                return;
              }
              localAttribute = "이미지";
              value = "변경됨";
              break;
            }
            case "costChangeCount": {
              if (Number(value) < 1) {
                return;
              }
              localAttribute = "처리 비용";
              value = "변경됨";

              break;
            }
            case "completionReport": {
              localAttribute = "처리 내용";
              break;
            }
            case "customerNotification": {
              localAttribute = "고객 답변";
              break;
            }
            case "isReopened": {
              localAttribute = "재처리 요청";
              value = "요청됨";
              break;
            }
            case "workOrderType": {
              localAttribute = "업무 구분";
              value = (WorkOrderType.SERVICE && "서비스 관리") || (WorkOrderType.SPACE && "공간 관리");
              break;
            }
            case "reporterName": {
              localAttribute = "접수 고객 이름";
              break;
            }
            case "reporterEmail": {
              localAttribute = "접수 고객 이메일";
              break;
            }
            case "isSendAlarm": {
              localAttribute = "업무 알림톡";
              value = (value as any) === "true" ? "활성화됨" : "비활성화됨";
              break;
            }
            case "reporterPhone": {
              localAttribute = "접수 고객 번호";
              value = formatPhoneNumber(String(value)) || "";
              break;
            }

            default:
              return;
            // return;
          }

          revision.localAttributes.push(localAttribute);
          revision.attributes.push(prevKey);
          revision.values.push(value);
        });
        return revision;
      })
      .reverse();

    return revisions;
  }, [originalRevisions, pageNum, officeInfos]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents">
            <section className="contents-container__grid workorder-history" style={{ overflow: "auto" }}>
              <div style={{ width: 1070, height: 900 }}>
                <table className="inner-table text-left" width={"100%"}>
                  <thead>
                    <tr className="text-center border-y-gray" style={{ height: 50 }}>
                      <th>
                        <span>No</span>
                      </th>
                      <th>
                        <span>변경유형</span>
                      </th>
                      <th>
                        <span>변경 영역</span>
                      </th>
                      <th>
                        <span>Changed Field</span>
                      </th>
                      <th>
                        <span>수정 내용</span>
                      </th>
                      <th>
                        <span>수정 일시</span>
                      </th>
                      <th>
                        <span>수정자</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {revisions.map((revision, revisionIndex: number) => (
                      <tr key={revision.key}>
                        {/* no */}
                        <td width={56}>
                          <div className="flex-center-center px16">{revision.no}</div>
                        </td>

                        {/* 변경유형 */}
                        <td width={88} className="">
                          <div className="flex-center-center px16">{revision.revisionTypeText}</div>
                        </td>

                        {/* 변경 영역 */}
                        <td width={138}>
                          <div>
                            <ul className="minmax138">
                              {revision.localAttributes.length > 0 ? (
                                revision.localAttributes.map((item, index) => (
                                  <li key={item + revisionIndex + index} className="w-100  px16 border-bottom-gray">
                                    <div className="flex-center " style={{ height: "50px" }}>
                                      <span>{item}</span>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <div className="px16">-</div>
                              )}
                            </ul>
                          </div>
                        </td>

                        {/* Changed Field */}
                        <td width={164}>
                          <div>
                            <ul className="minmax164">
                              {revision.attributes.length > 0 ? (
                                revision.attributes.map((item, index) => (
                                  <li key={item + revisionIndex + index} className="w-100 px16 border-bottom-gray">
                                    <div className="flex-center" style={{ height: "50px" }}>
                                      <span>{item}</span>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <div className="px16">-</div>
                              )}
                            </ul>
                          </div>
                        </td>

                        {/* 수정 내용 */}
                        <td width={312}>
                          <div>
                            <ul className="minmax312 ">
                              {revision.values.length > 0 ? (
                                revision.values.map((item, index) => (
                                  <li key={item + revisionIndex + index} className="w-100 px16 border-bottom-gray">
                                    <div className="flex-center w-100" style={{ height: "50px" }}>
                                      <BaseTooltip contents={item || "-"} />
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <div className="px16">-</div>
                              )}
                            </ul>
                          </div>
                        </td>

                        {/* 수정 일시 */}
                        <td width={116}>
                          <div className="px16 keep-all text-center">{revision.revisionInstantText}</div>
                        </td>

                        {/* 수정자 */}
                        <td width={196}>
                          <div className="flex-center-center px16">{revision.lastModifiedBy}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default WorkOrderHistory;
