import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Cell, useBlockLayout, useTable } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getReservationHistoryAsync } from "src/api/reservation/reservation-api";
import { ReservationHistoryResponse } from "src/api/reservation/reservation-types";
import GoToListButton from "src/components/GoToListButton";
import { ReservationState, getStatusText } from "../../reservation-types";
import { ViewYmdFormat } from "src/utils";
import moment from "moment";

const History = () => {
  const columns: any[] = useMemo(
    () => [
      {
        Header: "일시",
        accessor: "createdDate",
        width: 160,
        Cell: ({ value }: Cell<ReservationHistoryResponse>) => {
          return <p>{moment(value).format(ViewYmdFormat.FULL) || "-"}</p>;
        },
      },

      {
        Header: "구분",
        accessor: "eventType",
        width: 100,
      },
      {
        Header: "상태",
        accessor: "statusCode",
        width: 100,
        Cell: ({ value }: Cell<ReservationHistoryResponse>) => {
          const statueText = getStatusText(value);
          return (
            <div
              className={`${
                (value === ReservationState.ACCEPTED && "chip green") ||
                (value === ReservationState.CANCELLED && "chip red") ||
                (value === ReservationState.ACKNOWLEDGED && "chip green") ||
                (value === ReservationState.INCOMPLETE && "chip red")
              }`}
            >
              {statueText}
            </div>
          );
        },
      },
      {
        Header: "설명",
        accessor: "description",
        width: 200,
        Cell: ({ value }: Cell<ReservationHistoryResponse>) => {
          return (
            <div className="text-left w-100">
              <p className="ellipsis2">{value || "-"}</p>
            </div>
          );
        },
      },
      {
        Header: "주체",
        accessor: "operator",
        width: 190,
        Cell: ({ value }: Cell<ReservationHistoryResponse>) => {
          return (
            <div className="text-left w-100">
              <p>{value || "-"}</p>
            </div>
          );
        },
      },
      {
        Header: "대상 리소스",
        accessor: "targetResource",
        width: 140,
        Cell: ({ value }: Cell<ReservationHistoryResponse>) => {
          return <p>{value || "-"}</p>;
        },
      },
      {
        Header: "예약시간",
        accessor: "start",
        width: 180,
        Cell: ({ row, value }: Cell<ReservationHistoryResponse>) => {
          const date = moment(value).format(ViewYmdFormat.YYYY_MM_DD);
          const startTime = moment(row.original.start).format("HH:mm");
          const endTime = moment(row.original.end).format("HH:mm");

          return value ? <p>{`${date} ${startTime}-${endTime === "00:00" ? "24:00" : endTime}`}</p> : <p>-</p>;
        },
      },
    ],
    [],
  );

  // 변경내역 목록 조회
  const { executeAsync: getHistory } = useApiOperation(getReservationHistoryAsync);

  const [historyList, setHistoryList] = useState<ReservationHistoryResponse[]>([]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchApi = async () => {
        const response = await getHistory({ id });
        if (response.status >= 200 && response.status <= 299) {
          const historyData = response.data?.data?.histories;
          setHistoryList(historyData);
        }
      };
      fetchApi();
    }
  }, [getHistory, id]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({ data: historyList || [], columns }, useBlockLayout);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-article">
            <div className="contents-container__grid-contents">
              <section className="inner-tab-table">
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header) => {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              {header.render("Header")}
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {rows.map((row: any) => {
                      prepareRow(row);
                      return (
                        <div {...row.getRowProps()} className={`base-table__tr`}>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  {rows.length === 0 && (
                    <div className="base-table__tr table-cursor-unset">
                      <div className="base-table__td w-100 text-center">
                        <div className="w-100">
                          <span>데이터가 없습니다.</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area"></div>
      </div>
    </>
  );
};

export default History;
