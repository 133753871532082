import { useCallback, useEffect, useState } from "react";
import Select from "react-select/dist/declarations/src/Select";
import { NewContractSaveModel } from "src/api/contract/contract-types";
import { BaseButton, BaseModal } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import pagePath from "src/pagePath.json";
import ContractorInfo from "src/pages/contract/commonComponents/ContractorInfo";
import ContractProductEdit from "src/pages/contract/commonComponents/editComponent/ContractProductEdit";
import useContractApi from "src/pages/contract/hooks/useContractApi";
import { useToast } from "src/recoil/toast/hook";
import FOType from "./component/FOType";
import ProductCommomFacilitySelect from "./component/ProductCommomFacilitySelect";
import ServiceType from "./component/ServiceType";

const ContractApplication = () => {
  const navigate = useNavigate();
  const { openToast } = useToast();
  const { saveFirstContractData, sendQuoTation } = useContractApi();
  const [productDetail, setProductDetail] = useState<any>({});
  const [selectedPeriodOption, setSelectedPeriodOption] = useState<Select[]>([]);
  const [confirm, setConfirm] = useState(false);
  const [savedId, setSavedId] = useState(0);
  const [saveFlag, setSaveFlag] = useState(false);

  const [saveData, setSaveData] = useState<NewContractSaveModel>({
    mbOrganizationId: 0,
    productId: 0,

    contractApplyType: "CONTRACT",
    useStartTime: "",
    useEndTime: "",

    applicantName: "",
    applicantCorporation: "",
    isAgreeTerms: true,

    applicantMobile: "",
    applicantMemberNo: "",
  });
  const validationCheck = useCallback(
    (saveData: any) => {
      let result = true;

      if (saveData.productId === undefined || Number(saveData.productId) === 0) {
        openToast({ content: "상품 정보는 필수 입력 항목입니다.", isSystemToast: true });
        result = false;
        return result;
      }
      if (saveData.applicantMemberNo === undefined || saveData.applicantMemberNo === "") {
        openToast({ content: "신청자 정보는 필수 입력 항목입니다.", isSystemToast: true });
        result = false;
        return result;
      }
      if (productDetail.productType === "TIME_COURT") {
        if (saveData.selectedFacility === undefined) {
          openToast({ content: "공용공간 선택은 필수 입니다.", isSystemToast: true });
          result = false;
          return result;
        }
      }
      if (saveData.useStartTime === undefined || saveData.useStartTime === "") {
        openToast({ content: "이용 시작일은 필수 입력 항목입니다.", isSystemToast: true });
        result = false;
        return result;
      }
      if (saveData.useEndTime === undefined || saveData.useEndTime === "") {
        openToast({ content: "이용 시작일은 필수 입력 항목입니다.", isSystemToast: true });
        result = false;
        return result;
      }
      if (productDetail.productType && productDetail.productType !== "TIME_COURT") {
        if (Number(saveData.useTerm) === 0) {
          openToast({ content: "기간은 필수 입력 항목입니다.", isSystemToast: true });
          result = false;
          return result;
        }
      }

      return result;
    },
    [openToast, productDetail.productType],
  );

  const moveDetail = (savedId: Number) => {
    setSaveFlag(false);
    const path = pagePath.contract.detail.replace(":id", String(savedId));
    navigate(path);
  };
  const onSaveData = useCallback(
    async (saveData: any) => {
      const result: boolean = validationCheck(saveData);
      if (result === true) {
        //견적서 생성 요청을 위한 API
        if (productDetail.productType === "TIME_COURT") {
          const sendData = {
            quotationItemList: [
              {
                productId: saveData.productId,
                buildingCommonFacilityId: saveData?.selectedFacility!.id!,
                startDate: saveData.useStartTime,
                endDate: saveData.useEndTime,
              },
            ],
          };
          const saveQuotation = await sendQuoTation(sendData);

          if (saveQuotation) {
            saveData.quotationId = saveQuotation.id;
          }
        }

        saveData.mbOrganizationId = 0;
        saveData.contractApplyType = "CONTRACT";
        saveData.isAgreeTerms = true;
        delete saveData.useTerm;

        const saveResult: any = await saveFirstContractData(saveData);

        if (saveResult.response.status > 199 && saveResult.response.status < 300) {
          const savedContractId = saveResult.response.data.data.contractId;
          setSavedId(savedContractId);
          setSaveFlag(true);
        }
      }
    },
    [productDetail.productType, saveFirstContractData, sendQuoTation, validationCheck],
  );
  const makePeriodOptions = (productDetail: any) => {
    let arr: any = [];

    if (productDetail.productType === "FULL_COURT") {
      const min = Number(productDetail.minLeasePeriod);
      const max = Number(productDetail.maxLeasePeriod);
      for (let i = min; i <= max; i++) {
        const option = { label: i + "개월", value: i };
        arr.push(option);
      }
    }
    if (productDetail.productType === "OPEN_COURT") {
      const min = Number(productDetail.minLeasePeriod);
      const max = Number(productDetail.maxLeasePeriod);
      for (let i = min; i <= max; i++) {
        const option = { label: i + "개월", value: i };
        arr.push(option);
      }
    }
    if (productDetail.productType === "NON_RESIDENT_OFFICE") {
      const min = Number(productDetail.minLeasePeriod);
      const max = Number(productDetail.maxLeasePeriod);
      for (let i = min; i <= max; i++) {
        const option = { label: i + "개월", value: i };
        arr.push(option);
      }
    }

    setSelectedPeriodOption(arr);

    return arr;
  };
  useEffect(() => {
    if (productDetail) {
      makePeriodOptions(productDetail);
    }
  }, [productDetail]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <ContractProductEdit saveData={saveData} saveObject={setSaveData} productDetail={productDetail} setProductDetail={setProductDetail} />
          <ContractorInfo detailData={{}} detailFlag={false} productDetail={productDetail} saveData={saveData} setSaveData={setSaveData} />
          {(productDetail?.id === undefined ||
            productDetail?.productType === "FULL_COURT" ||
            productDetail?.productType === "OPEN_COURT" ||
            productDetail?.productType === "NON_RESIDENT_OFFICE") && (
            <FOType saveData={saveData} setSaveData={setSaveData} productDetail={productDetail} selectedPeriodOption={selectedPeriodOption} />
          )}
          {productDetail?.productType === "TIME_COURT" && (
            <>
              <ProductCommomFacilitySelect saveObject={saveData} setSaveData={setSaveData} />
              <ServiceType saveData={saveData} productDetail={productDetail} setSaveData={setSaveData} />
            </>
          )}
          {productDetail?.productType === "MAINTENANCE_FEE" && <></>}

          <BaseModal
            isOpen={confirm}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={(e?: any) => {
              console.log(e);
              onSaveData(saveData);
              setConfirm(false);
            }}
            onClose={() => setConfirm(false)}
            title={"저장 하시겠습니까?"}
          ></BaseModal>
          <BaseModal isOpen={saveFlag} btnRightTitle="확인" onClick={() => moveDetail(savedId)} title={"저장 되었습니다."}></BaseModal>
          {/* <BaseModal isOpen={oneHour} btnRightTitle="확인" onClick={() => setOneHour(false)} title={"최소 이용시간은 1시간입니다."}></BaseModal> */}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="right-area">
          <BaseButton title="저장" className="size-large" onClick={() => setConfirm(true)} />
        </div>
      </div>
    </>
  );
};

export default ContractApplication;
