import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Cell } from "react-table";
import { getContractMagage_RCA25 } from "src/api/contract/contract-api";
import { ContractManagaDetail } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { WorkOrderDetailModel, WorkOrderVisitor } from "src/api/work-order/workorder-types";
import GoToListButton from "src/components/GoToListButton";
import InnerTable from "src/components/InnerTable";
import Link from "src/components/PartnerLink";
import { PagePath } from "src/pages/product/details";
import { ViewYmdFormat, YmdFormat, onChangeKoreanDays } from "src/utils";

type Props = {
  workOrder?: WorkOrderDetailModel;
};

const WorkOrderAccess = ({ workOrder }: Props) => {
  const [spaceProductName, setSpaceProductName] = useState("");
  const contractId = useMemo(
    () => (workOrder && workOrder.workOrderSheetAccessZoneList!.length > 0 ? workOrder?.workOrderSheetAccessZoneList![0].contractId : ""),
    [workOrder],
  );
  const contractVisitorList = useMemo(
    () => workOrder?.contractVisitorList?.sort((a, b) => moment(a.visitStartTime).diff(moment(b.visitEndTime))) || [],
    [workOrder],
  );

  const { executeAsync: getContractManage } = useApiOperation(getContractMagage_RCA25);

  useEffect(() => {
    const fetchedContract = async () => {
      const contractManageId =
        workOrder && workOrder.workOrderSheetAccessZoneList!.length > 0 ? workOrder.workOrderSheetAccessZoneList![0].contractManageId : "";
      if (!contractManageId) return;
      const { data, status } = await getContractManage({ id: Number(contractManageId) });
      if (status >= 200 && status <= 299) {
        const result = data.data as ContractManagaDetail;
        setSpaceProductName(result.contractManage.spaceProductName || "");
      }
    };

    fetchedContract();
  }, [getContractManage, workOrder]);

  const accessColumns = [
    {
      Header: "No",
      accessor: "contractVisitorId",
      width: 80,
      Cell: ({ row }: Cell<WorkOrderVisitor>) => {
        return <span>{row.index + 1}</span>;
      },
    },
    {
      Header: "작업자",
      accessor: "visitorName",
      width: 160,
    },
    {
      Header: "출입 기간",
      accessor: "visitStartTime",
      width: 300,
      Cell: ({ row, value }: Cell<WorkOrderVisitor>) => {
        const startDate = moment(row.original.visitStartTime).format(YmdFormat.YYYY_MM_DD);
        const endDate = moment(row.original.visitEndTime).format(YmdFormat.YYYY_MM_DD);

        return (
          <div className="d-flex">
            <div className="mr30">
              <span>{startDate}</span>
              <span className="ml5">{`(${onChangeKoreanDays(startDate)})`}</span>
            </div>
            <div>
              <span>{endDate}</span>
              <span className="ml5">{`(${onChangeKoreanDays(endDate)})`}</span>
            </div>
          </div>
        );
      },
    },
    {
      Header: "출입 가능 시간",
      accessor: "",
      width: 120,
      Cell: ({ row }: Cell<WorkOrderVisitor>) => {
        const startTime = moment(row.original.visitStartTime).format(ViewYmdFormat.HH_MM);
        const endTime = moment(row.original.visitEndTime).format(ViewYmdFormat.HH_MM);
        return (
          <div>
            <span>{startTime}</span>
            <span className="mx5">-</span>
            <span>{endTime}</span>
          </div>
        );
      },
    },
    {
      Header: "상태",
      accessor: "visitStatus",
      width: 140,
      Cell: ({ value }: Cell<WorkOrderVisitor>) => {
        const VISITOR_STATUS: { [key: string]: { label: string; color: string } } = {
          VISIT_USE: { label: "사용", color: "status A" },
          VISIT_PAUSE: { label: "일시정지", color: "status B" },
          VISIT_DELETE: { label: "삭제", color: "status D" },
          VISIT_CLOSE: { label: "종료", color: "status D" },
        };
        let status = "";
        let color = "";
        for (const key in VISITOR_STATUS) {
          if (key === value) {
            status = VISITOR_STATUS[key].label;
            color = VISITOR_STATUS[key].color;
            break;
          }
        }
        return <div className={color}>{status}</div>;
      },
    },
    {
      Header: "상세",
      accessor: "",
      width: 140,
      Cell: ({ row }: Cell<WorkOrderVisitor>) => {
        return (
          <>
            <Link
              target="_blank"
              to={PagePath.visitor.detail
                .replace(":visitApplyNumber", String(row.original.visitApplyNumber))
                .replace(":contractApplyNumber", row.original.contractApplyNumber)}
              className="text-underline"
            >
              바로가기
            </Link>
            <span className="ic-target-blank"></span>
          </>
        );
      },
    },
  ];
  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            <div className="contents-container__sub-title">
              <div className="minmax200 pb4">
                <h2 className="font18">출입 관리</h2>
              </div>
            </div>
            <section className="contents-container__grid ">
              <div className="contents-container__grid-index">
                <p>출입 구역</p>
              </div>
              <div className="contents-container__grid-contents">
                {contractVisitorList.length > 0 ? (
                  <div className="flex-row flex-center-start">
                    <Link
                      to={PagePath.contract.detail.replace(":id", String(contractId)) + "?tab=accessLevel"}
                      target="_blank"
                      className="text-underline"
                    >
                      {spaceProductName}
                    </Link>
                    <span className="ic-target-blank"></span>
                  </div>
                ) : (
                  <div>-</div>
                )}
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>출입 권한</p>
              </div>
              <div className="contents-container__grid-contents">
                {contractVisitorList.length > 0 ? <InnerTable data={contractVisitorList} columns={accessColumns} /> : <div>-</div>}
              </div>
            </section>
          </article>
        </div>
      </div>

      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default WorkOrderAccess;
