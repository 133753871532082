import { useMemo } from "react";
import { Cell } from "react-table";
import { MgmtOfficeModel, MgmtPhoneType } from "src/api/building/building-types";
import { BaseButton } from "src/components";
import InnerTable from "src/components/InnerTable";
import { useModal } from "src/recoil/modalState/hook";
import { formatPhoneNumber } from "src/utils";
import { MgmtSectionType } from "../MgmtOfficeDetail";
import { BaseTooltip } from "src/components";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

type Props = {
  managementDetail: MgmtOfficeModel;
  handleDeleteTableRow: (type: MgmtSectionType, body: MgmtPhoneType[]) => Promise<void>;
  handleOpenSectionForm: (type: MgmtSectionType, detail?: MgmtOfficeModel) => void;
  isAuthority: { editable: boolean; deletable: boolean };
};
const MgmtContactSection = ({ managementDetail, handleDeleteTableRow, handleOpenSectionForm, isAuthority }: Props) => {
  const { setBaseModal } = useModal();
  // const { isAuthority } = usePartnerAuthority();

  const columns: any = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 50,
      },
      {
        Header: "연락처명",
        accessor: "name",
        width: 295,
      },
      {
        Header: "전화번호",
        accessor: "number",
        width: 235,
        Cell: ({ row, value }: Cell<MgmtPhoneType>) => {
          return formatPhoneNumber(value);
        },
      },
      {
        Header: "대표지정",
        accessor: "isPrimary",
        width: 130,
        Cell: ({ row, value }: Cell<MgmtPhoneType>) => {
          return value ? "대표" : "-";
        },
      },
      {
        Header: (
          <div className="flex-center">
            <span className="mr5">Taap 비상 연락</span>
            <BaseTooltip touchIcon={"QUESTION"} size={16} tooltip="선택한 연락처가 [Taap > 도와주세요 > 비상 연락]에 노출됩니다." />
          </div>
        ),
        accessor: "isEmergencyContact",
        width: 200,
        Cell: ({ row, value }: Cell<MgmtPhoneType>) => {
          return value ? "노출" : "-";
        },
      },
      {
        Header: "수정",
        width: 80,
        Cell: ({ row }: Cell<MgmtPhoneType>) => {
          return <BaseButton className="size-small color-white" title={"수정"} onClick={() => handleOpenSectionForm("contact", row.original)} />;
        },
      },
      {
        Header: "삭제",
        width: 80,
        Cell: ({ row }: Cell<MgmtPhoneType>) => {
          return (
            <BaseButton
              title=""
              className="base-trash-btn color-gray border-none"
              onClick={() => {
                const deleteData: MgmtPhoneType = { ...row.original, cmdType: "D" };
                if (deleteData.isPrimary === true) {
                  setBaseModal({
                    isOpen: true,
                    title: "대표 번호는 삭제할 수 없습니다.",
                    children: <p>대표 설정을 먼저 해제해 주세요.</p>,
                    btnRightTitle: "확인",
                    onClick: () => setBaseModal({ isOpen: false }),
                  });

                  return;
                }
                //
                setBaseModal({
                  isOpen: true,
                  title: "연락처를 삭제하시겠습니까?",
                  btnRightTitle: "확인",
                  children: row.original.isEmergencyContact && <p>Taap 비상 연락 노출이 해제됩니다. </p>,
                  btnLeftTitle: "취소",
                  // payload: { officePhoneList: [deleteData] },
                  onClick: () => {
                    handleDeleteTableRow("contact", [deleteData]);
                    setBaseModal({ isOpen: false });
                  },
                  onClose: () => setBaseModal({ isOpen: false }),
                });
              }}
            />
          );
        },
      },
    ],
    [handleDeleteTableRow, handleOpenSectionForm, setBaseModal],
  );

  const tableData = useMemo(
    () => (managementDetail?.officePhoneList && managementDetail?.officePhoneList.length > 0 ? managementDetail?.officePhoneList : []),
    [managementDetail?.officePhoneList],
  );
  return (
    <article className="contents-container__wrap-article">
      <div className="flex-center-between">
        <div className="contents-container__sub-title">
          <h2>연락처</h2>
        </div>
        {isAuthority.editable && (
          <BaseButton title="등록" disabled={!managementDetail.id && true} className="size-small" onClick={() => handleOpenSectionForm("contact")} />
        )}
      </div>
      <InnerTable
        data={tableData}
        columns={columns.filter((column: any) => {
          let returnColumn = column;
          if (!isAuthority.editable) {
            returnColumn = column.Header !== "수정";
          }
          if (!isAuthority.deletable) {
            returnColumn = column.Header !== "삭제";
          }
          if (!isAuthority.editable && !isAuthority.deletable) {
            returnColumn = column.Header !== "수정" && column.Header !== "삭제";
          }
          return returnColumn;
        })}
        className="overflow-x-hidden"
      />
    </article>
  );
};

export default MgmtContactSection;
