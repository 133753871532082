import { Ac2ProductAccessGroupModel } from "src/api/access/productac-types";
import { DeviceType, ExecuteResultType, IndoorUnitKeyTypes, IndoorUnitMode } from "./monitoring-types";
import { roundFloat } from "src/utils/common-util";

export const getUnit = (type: IndoorUnitKeyTypes) => {
  return IndoorUnitMode[type];
};

export const monitoringTooltipData = (iotData?: ExecuteResultType, accessData?: Ac2ProductAccessGroupModel) => {
  const unknownMessage = "알수없음";
  const tooltipChildren = [
    {
      type: DeviceType.ACCESS,
      value: (
        <div>
          <p className="popover-gray__title">{accessData?.deviceName}</p>
          <p>{`상태 : ${accessData?.deviceStatusInfo?.deviceStatus}`}</p>
          <p>{`장치 id : ${accessData?.externalDeviceId}`}</p>
        </div>
      ),
    },
    {
      type: DeviceType.SENSOR,
      value: (
        <div>
          <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
          <p>{`상태 : ${(iotData?.result?.motion && iotData?.result?.motion.value) || unknownMessage}`}</p>
        </div>
      ),
    },
    {
      type: DeviceType.INDOOR_UNIT,
      value: (
        <div>
          {iotData?.platform === "ST" && (
            <>
              <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
              <p>{`상태 : ${(iotData?.result?.switch && iotData?.result?.switch.value) || unknownMessage}`}</p>
              <p>{`모드 : ${(iotData?.result?.airConditionerMode && getUnit(iotData?.result?.airConditionerMode.value)) || unknownMessage}`}</p>
              <p>{`희망 온도 : ${
                (iotData?.result?.error
                  ? unknownMessage
                  : iotData?.result?.coolingSetpoint && roundFloat(iotData?.result?.coolingSetpoint.value) + "°C") || unknownMessage
              }`}</p>
              <p>{`측정 온도 : ${
                (iotData?.result?.error ? unknownMessage : iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.value) + "°C") ||
                unknownMessage
              }`}</p>
            </>
          )}
          {iotData?.platform === "B_IOT" && (
            <>
              <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
              <p>{`상태 : ${(iotData?.result?.operation && iotData?.result?.operation.power) || unknownMessage}`}</p>
              <p>{`모드 : ${(iotData?.result?.mode && getUnit(iotData?.result?.mode.mode)) || unknownMessage}`}</p>
              <p>{`희망 온도 : ${
                (iotData?.result?.error ? unknownMessage : iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.desired) + "°C") ||
                unknownMessage
              }`}</p>
              <p>{`측정 온도 : ${
                (iotData?.result?.error ? unknownMessage : iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.current) + "°C") ||
                unknownMessage
              }`}</p>
            </>
          )}
        </div>
      ),
    },
    {
      type: DeviceType.TEMPERATURE,
      value: (
        <div>
          <p className="popover-gray__title">평균 온도</p>
          {iotData?.platform === "ST" && (
            <p>{`${(iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.value)) + "°C" || unknownMessage} ${
              iotData?.internalDisplayName
            }`}</p>
          )}
          {iotData?.platform === "B_IOT" && (
            <p>{`${(iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.current)) + "°C" || unknownMessage} ${
              iotData?.internalDisplayName
            }`}</p>
          )}
        </div>
      ),
    },
    {
      type: DeviceType.LIGHT,
      value: (
        <div>
          <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
          <p>{`상태 : ${(iotData?.result?.switch && iotData?.result?.switch.value) || unknownMessage}`}</p>
          <p>{`밝기 : ${(iotData?.result?.level && iotData?.result.level.value) || unknownMessage}`}</p>
        </div>
      ),
    },
    {
      type: DeviceType.MENS_TOILET,
      value: (
        <div>
          <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
          {iotData?.result?.contact && <p>{`상태 : ${iotData?.result?.contact.value ? iotData?.result?.contact.value : unknownMessage}`}</p>}
          {iotData?.result?.motion && <p>{`상태 : ${iotData?.result?.motion.value ? iotData?.result?.motion.value : unknownMessage}`}</p>}
        </div>
      ),
    },
    {
      type: DeviceType.WOMEN_TOILET,
      value: (
        <div>
          <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
          {iotData?.result?.contact && <p>{`상태 : ${iotData?.result?.contact.value ? iotData?.result?.contact.value : unknownMessage}`}</p>}
          {iotData?.result?.motion && <p>{`상태 : ${iotData?.result?.motion.value ? iotData?.result?.motion.value : unknownMessage}`}</p>}
        </div>
      ),
    },
  ];

  const iotChildern =
    tooltipChildren.find((item) => item.type === iotData?.labelType) || tooltipChildren.find((item) => item.type === DeviceType.ACCESS);

  return iotChildern?.value;
};
