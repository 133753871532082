import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BillingDetail } from "src/api/billingSchedule/billing-type";
import {
  ContractDetail,
  ContractorNoti,
  ContractStep,
  DetailTotalData,
  disabledBeforeContractAccept,
  disabledBeforeContractPlan,
  disabledEsignEdit,
  priceChangeStatus2,
} from "src/api/contract/contract-types";
import { Select } from "src/api/public-types";
import { BaseButton, BaseDatePicker, BaseInput, BaseRadio, BaseSelect, BaseTooltip } from "src/components";
import BaseSection from "src/components/layout/BaseSection";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, YmdFormat, formatPhoneNumber, numberToStringWithComma, parsedPhoneNumber, validatePhoneNumberReturnBoolean } from "src/utils";
import useContactApi from "../../hooks/useContractApi";
import useEsignApi from "../../hooks/useEsignApi";
import DirectFileUpload from "../DirectFileUpload";
import ModifyAmountModalAccordion from "../modal/ModifyAmountModalAccordion";
import SnapShotModal from "../modal/SnapShotModal";
import EsignOnLink from "./EsignOnLink";
import RangeDatepicker from "src/components/RangeDatepicker";
// eslint-disable-next-line no-useless-escape
const checkEmail = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

interface BasicProps {
  detailData: DetailTotalData;
  saveFlags: boolean;
  popupState: boolean;
  setEditable: any;
  setSaveFlags: any;
  setData: Function;
  setPopupState: any;
  setCommonModals: any;
  setEditConfrim: any;
}
const payStatusOption = [
  { label: "결제대기", value: "PAY_WAIT" },
  { label: "결제완료", value: "PAY_COMPLETE" },
];
const ContractInfoEdit = ({
  detailData,
  saveFlags,
  setEditable,
  setData,
  setSaveFlags,
  setCommonModals,
  setPopupState,
  setEditConfrim,
}: BasicProps) => {
  const { openToast } = useToast();
  const { editContractData } = useContactApi();
  const { getPartnerEsignData } = useEsignApi();
  const { contract, contractManage, product, scheduleList } = detailData;
  const [textGrey] = useState("contents-container__grid ");
  const [editContract, setEditContract] = useState<ContractDetail>({});
  const [isSnapShotOpen, setIsSnapShotOpen] = useState(false);
  const [isEsign, setIsEsign] = useState(false);
  const [isModifyAmountOpen, setIsModifyAmountOpen] = useState(false);
  const [, setSelectedPeriodOptions] = useState<Select[]>([]);
  const [editRentalSchedule, setEditRentalSchedule] = useState<BillingDetail>();
  const [editNonTaxNotiList, setEditNonTaxNotiList] = useState<Array<ContractorNoti>>([]);
  const [editTaxNotiList, setEditTaxNotiList] = useState<Array<ContractorNoti>>([]);
  const [s2TaxNotiOne, setS2TaxNotiOne] = useState<any>({
    email: "",
    isDeleted: false,
    contact: "",
    receiveNotiType: "TAX_INVOICE",
    description: "",
    errorTextEmail: "",
  });
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    contract?.contractStartTime ? moment(contract?.contractStartTime).toDate() : null,
    contract?.contractEndTime ? moment(contract?.contractEndTime).toDate() : null,
  ]);

  const onSaveContractData = useCallback(async () => {
    const send = _.cloneDeep(editContract!);
    let billContactList = editNonTaxNotiList.concat(editTaxNotiList);
    if (billContactList.length > 0) {
      let checker = false;
      billContactList.forEach((noti: ContractorNoti) => {
        if (noti.receiveNotiType === "TALK_OFFICIALDOC") {
          if ((noti.email === "", noti.contact === "")) {
            checker = true;
            openToast({ content: "공문 수신자 이메일, 휴대폰", isSystemToast: true });
          }
        }
        if (noti.receiveNotiType !== "TALK_OFFICIALDOC") {
          if (noti.email === "") {
            checker = true;
          }
        }
      });
      if (checker) {
        return;
      }
      billContactList.forEach((noti: ContractorNoti) => {
        if (noti.receiveNotiType === "TALK_OFFICIALDOC") {
          noti.contact = parsedPhoneNumber(noti.contact!);
        }
      });
    }
    send.billContactList = billContactList;

    const result: any = await editContractData(contract!, send);

    if (result.response.status > 199 && result.response.status < 300) {
      setCommonModals({
        message: "저장 되었습니다.",
        title: "계약 정보 변경",
      });
      setPopupState(true);
      setEditConfrim(false);
      setSaveFlags(false);
      setEditable(false);
      setData();
      return;
    }

    if (result.validationPass === false) {
      setCommonModals({
        message: result.message,
        title: "계약 정보 변경",
      });
      setPopupState(true);
      setSaveFlags(false);
      // setEditable(false);
      setEditConfrim(false);

      return;
    }
  }, [
    editContract,
    editNonTaxNotiList,
    editTaxNotiList,
    editContractData,
    contract,
    openToast,
    setCommonModals,
    setPopupState,
    setEditConfrim,
    setSaveFlags,
    setEditable,
    setData,
  ]);

  const getPartnerEsignCheck = useCallback(async () => {
    const response = await getPartnerEsignData();
    let checker = false;
    if (response.data.data.eSignonClient.businessId) {
      if (Number(response.data.data.eSignonClient.businessId) > 0) {
        checker = true;
      }
    }
    return checker;
  }, [getPartnerEsignData]);

  const initDatas = useCallback(() => {
    if (detailData) {
      setEditContract(contract!);

      const options: Select[] = [];
      if (product.productType === "FULL_COURT") {
        const min = Number(product.minLeasePeriod);
        const max = Number(product.maxLeasePeriod);
        for (let i = min; i <= max; i++) {
          const option = { label: i + "개월", value: String(i) };
          options.push(option);
        }
      }
      if (product.productType === "OPEN_COURT") {
        const min = Number(product.minLeasePeriod);
        const max = Number(product.maxLeasePeriod);
        for (let i = min; i <= max; i++) {
          const option = { label: i + "개월", value: String(i) };
          options.push(option);
        }
      }
      setSelectedPeriodOptions(options);
      const sd = scheduleList?.find((schedule: BillingDetail) => schedule.supplyType === "RENTAL");

      const billContractorNoti = _.cloneDeep(contract?.billContactList);

      const talk = billContractorNoti!.filter((noti: ContractorNoti) => noti.receiveNotiType === "TALK_OFFICIALDOC");
      const tax = billContractorNoti!.filter((noti: ContractorNoti) => noti.receiveNotiType !== "TALK_OFFICIALDOC");

      if (detailData.product.productType === "TIME_COURT" && tax.length > 0) {
        setS2TaxNotiOne(tax[0]);
      }

      setEditNonTaxNotiList(talk);
      setEditTaxNotiList(tax);
      setEditRentalSchedule(sd);
      // console.log("contract?.eSignList.length", contract?.eSignList!.length);
      if (Array.isArray(contract?.eSignList) && contract?.eSignList.length! > 0) {
        setIsEsign(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  useEffect(() => {
    initDatas();
  }, [initDatas]);

  useEffect(() => {
    if (saveFlags === true) {
      onSaveContractData();
    } else {
      //취소 클릭 시
      initDatas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFlags]);

  return (
    <>
      <div className="flex-center">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index flex-start">
            <div className="flex-center">
              <p>계약명</p>
              <BaseTooltip
                touchIcon={"QUESTION"}
                className="ml4 mt1"
                size={16}
                tooltip="계약명을 설정하면, 앱/알림톡/이메일 공문에 관리자가 입력한 정보가 출력됩니다."
              />
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax300">
              <BaseInput
                readonly={
                  //신청취소/이용완료/해지완료’ 상태일 경우 BizException 처리
                  contract?.contractStep === "APPLY_CANCEL" ||
                  contract?.contractStep === "USE_COMPLETE" ||
                  contract?.contractStep === "TERMINATE_COMPLETE"
                }
                name={"contractName"}
                value={editContract?.contractName ? String(editContract?.contractName) : ""}
                onChange={(value: string) => {
                  const temp = _.cloneDeep(editContract!);
                  temp.contractName = value;
                  setEditContract(temp);
                }}
              />
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>계약일시</p>
          </div>
          <div className="contents-container__grid-contents">
            {detailData?.product?.productType !== "TIME_COURT" ? (
              <div className="flex-row flex-center-start">
                <div className="minmax300">
                  <BaseDatePicker
                    readonly={
                      contract?.contractStep === "APPLY_CANCEL" ||
                      contract?.contractStep === "CONTRACT_ACCEPT" ||
                      contract?.contractStep === "USE_APPROVAL" ||
                      contract?.contractStep === "USE_PROGRESS" ||
                      contract?.contractStep === "TERMINATE_RECEIVED" ||
                      contract?.contractStep === "TERMINATE_COMPLETE" ||
                      contract?.contractStep === "USE_COMPLETE"
                    }
                    name={"contractDate"}
                    type="full"
                    selectedDate={editContract?.contractDate ? moment(editContract?.contractDate).toDate() : null}
                    setDate={(value: string) => {
                      const temp = _.cloneDeep(editContract!);

                      temp.contractDate = moment(value).format(YmdFormat.WITH_TIME_ZONE);
                      setEditContract(temp);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="flex-row flex-center-start">
                <div className="minmax180 mr8">
                  <p>{moment(editContract?.contractDate).format(ViewYmdFormat.FULL)}</p>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      <div className="flex-center">
        <section className={textGrey}>
          <div className="contents-container__grid-index">
            <p>계약자명</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax300">
              <BaseInput
                readonly={
                  contract?.contractStep === "APPLY_RECEIVED" ||
                  contract?.contractStep === "APPLY_CANCEL" ||
                  contract?.contractStep === "USE_COMPLETE" ||
                  contract?.contractStep === "TERMINATE_COMPLETE"
                }
                name={"applicantName"}
                value={editContract?.applicantName ? String(editContract?.applicantName) : ""}
                onChange={(value: string) => {
                  const temp = _.cloneDeep(editContract!);
                  temp.applicantName = value;
                  setEditContract(temp);
                }}
              />
            </div>
          </div>
        </section>
        {detailData?.product?.productType !== "TIME_COURT" ? (
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>이용 기간</p>
            </div>
            <div className="contents-container__grid-contents ">
              <div className="minmax300">
                <RangeDatepicker
                  dateRange={dateRange}
                  onChange={(dateRange: [Date | null, Date | null]) => {
                    setDateRange(dateRange);
                    const [startDate, endDate] = dateRange;
                    if (!startDate || !endDate) {
                      return;
                    }
                    const temp = _.cloneDeep(editContract);
                    const start = moment(startDate);
                    const end = moment(endDate);
                    const durations = moment.duration(end.diff(start)).asMonths();
                    temp.contractTerm = parseInt(String(durations));
                    temp.contractStartTime = start.format(ViewYmdFormat.YYYY_MM_DD);
                    temp.contractEndTime = end.format(ViewYmdFormat.YYYY_MM_DD);

                    setEditContract(temp);
                  }}
                  disabled={
                    contract?.contractStep === "APPLY_CANCEL" ||
                    contract?.contractStep === "CONTRACT_ACCEPT" ||
                    contract?.contractStep === "USE_APPROVAL" ||
                    contract?.contractStep === "USE_PROGRESS" ||
                    contract?.contractStep === "TERMINATE_RECEIVED" ||
                    contract?.contractStep === "TERMINATE_COMPLETE" ||
                    contract?.contractStep === "USE_COMPLETE"
                  }
                />
              </div>
            </div>
          </section>
        ) : (
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>계약 번호</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <span className="mr10">{contract?.contractNumber ? contract?.contractNumber : "-"}</span>
                {textGrey !== "" && (
                  <BaseButton
                    title="계약 상품정보 스냅샷"
                    className="color-white"
                    disabled={
                      contract?.contractStep === "APPLY_RECEIVED" ||
                      contract?.contractStep === "APPLY_CANCEL" ||
                      contract?.contractStep === "APPLY_CONFIRM"
                    }
                    onClick={() => setIsSnapShotOpen(true)}
                  />
                )}
                <SnapShotModal
                  isOpen={isSnapShotOpen}
                  contractId={Number(contract?.contractId)}
                  detailData={detailData}
                  onClose={() => {
                    setIsSnapShotOpen(false);
                  }}
                />
              </div>
            </div>
          </section>
        )}
      </div>
      {detailData?.product?.productType !== "TIME_COURT" ? (
        <div className="flex-center">
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>보증금</p>
            </div>
            <div className="contents-container__grid-contents">
              <p>{numberToStringWithComma(Number(editContract?.earnestAmount) + Number(editContract?.balanceAmount)) + " 원"}</p>
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <div className="flex-center font14">
                이용료(부가세 별도)
                <BaseTooltip
                  contents={""}
                  tooltip={"계약 시 협의된 부가세를 제외한 월 이용료를 입력해 주세요."}
                  type={"normal"}
                  className={"tooltip__trigger-icon"}
                />
              </div>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start ">
                <div className="minmax140 mr8">
                  <BaseInput
                    readonly={
                      disabledBeforeContractPlan.includes(contract?.contractStep!) === false || detailData?.product?.productType === "TIME_COURT"
                    }
                    name={"payAmount"}
                    type="text"
                    className={"text-right"}
                    value={numberToStringWithComma(Number(_.replace(String(editContract?.payAmount), new RegExp(",", "g"), "")))}
                    onChange={(value: string) => {
                      let payAmount = _.replace(String(value), new RegExp(",", "g"), "");

                      payAmount = _.replace(String(payAmount), new RegExp("-", "g"), "");

                      const temp = _.cloneDeep(editContract!);
                      temp.payAmount = Number(payAmount);
                      setEditContract(temp);
                    }}
                  />
                </div>
                <BaseButton
                  disabled={!priceChangeStatus2.includes(contract?.contractStep!)}
                  title={detailData?.product?.productType === "TIME_COURT" ? "청구 금액 확인" : "청구 금액 수정"}
                  className="color-white"
                  onClick={() => setIsModifyAmountOpen(true)}
                />

                <ModifyAmountModalAccordion
                  detailData={detailData}
                  isOpen={isModifyAmountOpen}
                  onClose={() => {
                    setIsModifyAmountOpen(false);
                  }}
                  onClick={() => {
                    setIsModifyAmountOpen(false);
                  }}
                  scheduleId={Number(editRentalSchedule?.scheduleId)}
                  viewOnly={false || detailData?.product?.productType === "TIME_COURT"}
                  setCommonModals={setCommonModals}
                  setPopupState={setPopupState}
                  setEditConfrim={setEditConfrim}
                />
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="flex-center">
          <BaseSection
            title={"세금계산서 수신 이메일"}
            children={
              <div className="minmax300">
                <div className="flex-row flex-center-start">
                  <BaseInput
                    readonly={
                      contract?.contractStep === "APPLY_CANCEL" ||
                      contract?.contractStep === "TERMINATE_COMPLETE" ||
                      contract?.contractStep === "APPLY_CONFIRM" ||
                      contract?.contractStep === "USE_COMPLETE"
                    }
                    name={`s2TaxNotiOne.email`}
                    value={s2TaxNotiOne.email}
                    onChange={(value: string) => {
                      console.log("value", value, checkEmail.test(value));
                      const d_s2TaxNotiOne = _.cloneDeep(s2TaxNotiOne!);
                      const temp = _.cloneDeep(editTaxNotiList);

                      if (checkEmail.test(value) === false) {
                        d_s2TaxNotiOne.errorTextEmail = "이메일 형식이 아닙니다.";
                        d_s2TaxNotiOne.email = "";
                        setS2TaxNotiOne(d_s2TaxNotiOne);
                      } else {
                        d_s2TaxNotiOne.errorTextEmail = "";
                        d_s2TaxNotiOne.email = value;
                        temp.forEach((talk: any, idx: number) => {
                          if (String(s2TaxNotiOne.billContactId) === String(talk.billContactId)) {
                            talk.email = value;
                            talk.cmdType = "U";
                          }
                        });
                        console.log("temp.billContactList", temp, contract!.billContactList!);
                        setS2TaxNotiOne(d_s2TaxNotiOne);
                        setEditTaxNotiList(temp);
                      }
                    }}
                    errorText={s2TaxNotiOne.errorTextEmail ? s2TaxNotiOne.errorTextEmail : ""}
                  />
                </div>
              </div>
            }
          />
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>계약 형태</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <BaseRadio
                  id="contractType1"
                  name="contractType"
                  className="mr10"
                  value={"true"}
                  label="직접 계약"
                  checked={String(isEsign) === "false" ? true : false}
                  onChange={() => {
                    setIsEsign(false);
                  }}
                  disabled={disabledEsignEdit.includes(contract?.contractStep!)}
                ></BaseRadio>
                <BaseRadio
                  id="contractType2"
                  name="contractType"
                  value={"false"}
                  label="전자 서명"
                  checked={String(isEsign) === "true" ? true : false}
                  onChange={async () => {
                    const checker = await getPartnerEsignCheck();
                    if (checker) {
                      setIsEsign(true);
                    } else {
                      setCommonModals({
                        message: "전자 서명을 이용하시려면 관리자에게 문의해 주세요.",
                        title: "파트너 권한이 없습니다.",
                      });
                      setPopupState(true);
                    }
                  }}
                  disabled={disabledEsignEdit.includes(contract?.contractStep!)}
                ></BaseRadio>
              </div>
            </div>
          </section>
        </div>
      )}
      <div className="flex-center">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>ㄴ 계약금</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax120 mr8">
                  <BaseSelect
                    isDisabled={disabledBeforeContractPlan.includes(contract?.contractStep!) === false}
                    name={"earnestPayStatus"}
                    value={editContract?.earnestPayStatus}
                    stateOptions={payStatusOption}
                    setStateValue={(text: string) => {
                      const temp = _.cloneDeep(editContract!);

                      temp.earnestPayStatus = text;
                      setEditContract(temp);
                    }}
                  />
                </div>
                <div className="minmax120">
                  <BaseInput
                    readonly={disabledBeforeContractPlan.includes(contract?.contractStep!) === false}
                    name={"earnestAmount"}
                    type="text"
                    className={"text-right"}
                    value={numberToStringWithComma(Number(_.replace(String(editContract?.earnestAmount), new RegExp(",", "g"), "")))}
                    onChange={(value: string) => {
                      let earnestAmount = _.replace(String(value), new RegExp(",", "g"), "");
                      earnestAmount = _.replace(String(earnestAmount), new RegExp("-", "g"), "");
                      const temp = _.cloneDeep(editContract!);

                      temp.earnestAmount = Number(earnestAmount);
                      setEditContract(temp);
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
        {detailData?.product?.productType !== "TIME_COURT" && (
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>납부 유형</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <BaseRadio
                  id="costType1"
                  name="costType"
                  className="mr10"
                  value={"COST_BILLING_MONTHLY"}
                  label="정기 납부"
                  checked={editContract?.costType === "COST_BILLING_MONTHLY" ? true : false}
                  onChange={() => {
                    const temp = _.cloneDeep(editContract!);
                    temp.costType = "COST_BILLING_MONTHLY";
                    setEditContract(temp);
                  }}
                  disabled={disabledBeforeContractPlan.includes(contract?.contractStep!) === false}
                ></BaseRadio>
                <BaseRadio
                  id="costType2"
                  name="costType"
                  value={"COST_BILLING_ONE_TIME"}
                  label="일시 납부"
                  checked={editContract?.costType === "COST_BILLING_ONE_TIME" ? true : false}
                  onChange={() => {
                    const temp = _.cloneDeep(editContract!);
                    temp.costType = "COST_BILLING_ONE_TIME";
                    setEditContract(temp);
                  }}
                  disabled={disabledBeforeContractPlan.includes(contract?.contractStep!) === false}
                ></BaseRadio>
              </div>
            </div>
          </section>
        )}
      </div>
      <div className="flex-center">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>ㄴ 잔금</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax120 mr8">
                  <BaseSelect
                    isDisabled={disabledBeforeContractAccept.includes(contract?.contractStep!) === false}
                    name={"balancePayStatus"}
                    value={editContract?.balancePayStatus}
                    stateOptions={payStatusOption}
                    setStateValue={(text: string) => {
                      const temp = _.cloneDeep(editContract!);

                      temp.balancePayStatus = text;
                      setEditContract(temp);
                    }}
                  />
                </div>
                <div className="minmax120">
                  <BaseInput
                    readonly={disabledBeforeContractPlan.includes(contract?.contractStep!) === false}
                    name={"balanceAmount"}
                    type="text"
                    className={"text-right"}
                    value={numberToStringWithComma(Number(_.replace(String(editContract?.balanceAmount), new RegExp(",", "g"), "")))}
                    onChange={(value: string) => {
                      let balanceAmount = _.replace(String(value), new RegExp(",", "g"), "");
                      balanceAmount = _.replace(String(balanceAmount), new RegExp("-", "g"), "");
                      const temp = _.cloneDeep(editContract!);
                      temp.balanceAmount = Number(balanceAmount);
                      setEditContract(temp);
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <div className="flex-center">
        {/* 계약 번호 */}
        {detailData?.product?.productType !== "TIME_COURT" && (
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>계약 번호</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <span className="mr10">{contract?.contractNumber ? contract?.contractNumber : "-"}</span>
                {textGrey !== "" && (
                  <BaseButton
                    title="계약 상품정보 스냅샷"
                    className="color-white"
                    disabled={
                      contract?.contractStep === "APPLY_RECEIVED" ||
                      contract?.contractStep === "APPLY_CANCEL" ||
                      contract?.contractStep === "APPLY_CONFIRM"
                    }
                    onClick={() => setIsSnapShotOpen(true)}
                  />
                )}
                <SnapShotModal
                  isOpen={isSnapShotOpen}
                  contractId={Number(contract?.contractId)}
                  detailData={detailData}
                  onClose={() => {
                    setIsSnapShotOpen(false);
                  }}
                />
              </div>
            </div>
          </section>
        )}
        {detailData?.product?.productType !== "TIME_COURT" && (
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>계약 형태</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <BaseRadio
                  id="contractType1"
                  name="contractType"
                  className="mr10"
                  value={"true"}
                  label="직접 계약"
                  checked={String(isEsign) === "false" ? true : false}
                  onChange={() => {
                    setIsEsign(false);
                  }}
                  disabled={disabledEsignEdit.includes(contract?.contractStep!)}
                ></BaseRadio>
                <BaseRadio
                  id="contractType2"
                  name="contractType"
                  value={"false"}
                  label="전자 서명"
                  checked={String(isEsign) === "true" ? true : false}
                  onChange={async () => {
                    const checker = await getPartnerEsignCheck();
                    if (checker) {
                      setIsEsign(true);
                    } else {
                      setCommonModals({
                        message: "전자 서명을 이용하시려면 관리자에게 문의해 주세요.",
                        title: "파트너 권한이 없습니다.",
                      });
                      setPopupState(true);
                    }
                  }}
                  disabled={disabledEsignEdit.includes(contract?.contractStep!)}
                ></BaseRadio>
              </div>
            </div>
          </section>
        )}
      </div>
      {isEsign ? (
        <EsignOnLink
          detailData={detailData}
          viewOnly={false}
          setData={setData!}
          setEditable={setEditable}
          initMainData={initDatas}
          setIsEsign={setIsEsign}
          data={editContract}
        />
      ) : (
        <DirectFileUpload contract={contract!} editContract={editContract!} setEditContract={setEditContract} />
      )}
      <div className="flex-start">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>관리비 청구 유형</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <>
                  <BaseRadio
                    disabled={contract?.contractStep! === ContractStep.APPLY_CANCEL}
                    id={"isMntContain_true"}
                    name={"isMntContain"}
                    label="관리비 포함"
                    value="true"
                    className="mr16"
                    checked={String(editContract?.isMntContain) === "true" ? true : false}
                    onChange={() => {
                      const temp = _.cloneDeep(editContract!);
                      temp.isMntContain = true;
                      setEditContract(temp);
                    }}
                  />
                  <BaseRadio
                    disabled={contract?.contractStep! === ContractStep.APPLY_CANCEL}
                    id={"isMntContain_false"}
                    name={"isMntContain"}
                    label="관리비 별도"
                    value="false"
                    checked={String(editContract?.isMntContain) === "false" ? true : false}
                    onChange={(e) => {
                      const temp = _.cloneDeep(editContract!);
                      temp.isMntContain = false;
                      setEditContract(temp);
                    }}
                  />
                </>
              </div>
            </div>
          </section>
        )}
      </div>
      <div className="flex-start">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"공문 수신자"}
            children={
              <div className="contents-container__grid-contents">
                {editNonTaxNotiList?.map(
                  (noti: ContractorNoti, index: number) =>
                    noti.isDeleted === false && (
                      <div className="flex-row flex-center" key={index}>
                        <>
                          <section className="contents-container__grid">
                            <div className="contents-container__grid-index">
                              <p>이메일</p>
                            </div>
                            <div className="contents-container__grid-contents">
                              <div className="minmax240 flex-row flex-center-start">
                                <BaseInput
                                  readonly={
                                    contract?.contractStep === "APPLY_CANCEL" ||
                                    contract?.contractStep === "TERMINATE_COMPLETE" ||
                                    contract?.contractStep === "APPLY_CONFIRM" ||
                                    contract?.contractStep === "USE_COMPLETE"
                                  }
                                  name={`billContact.email${index}`}
                                  value={noti.email}
                                  onChange={(value: string) => {
                                    const dump = _.cloneDeep(editNonTaxNotiList!);

                                    const checker = dump.filter((talk: any, idx: number) => idx !== index && talk.email === value);
                                    console.log("checker", checker);
                                    if (checker.length === 0) {
                                      const adds = dump.map((talk: any, idx: number) => {
                                        if (_.isEqual(talk, noti)) {
                                          talk.errorTextEmail = "";
                                          talk.email = value;
                                          if (Number(noti.billContactId) !== 0) {
                                            talk.cmdType = "U";
                                          }
                                        }
                                        return talk;
                                      });
                                      setEditNonTaxNotiList(adds);
                                    } else {
                                      const adds = dump.map((talk: any, idx: number) => {
                                        if (idx === index) {
                                          talk.errorTextEmail = "이미 등록된 연락처 입니다.";
                                        }
                                        return talk;
                                      });
                                      setEditNonTaxNotiList(adds);
                                    }
                                  }}
                                  onBlur={() => {
                                    const dump = _.cloneDeep(editNonTaxNotiList!);

                                    if (checkEmail.test(noti.email!) === false) {
                                      const adds = dump.map((talk: any, idx: number) => {
                                        if (idx === index) {
                                          talk.errorTextEmail = "이메일 형식이 아닙니다.";
                                          talk.email = "";
                                        }
                                        return talk;
                                      });
                                      setEditNonTaxNotiList(adds);
                                    }
                                  }}
                                  errorText={noti.errorTextEmail ? noti.errorTextEmail : ""}
                                />
                              </div>
                            </div>
                          </section>
                          <section className="contents-container__grid">
                            <div className="contents-container__grid-index">
                              <p>휴대폰</p>
                            </div>
                            <div className="contents-container__grid-contents">
                              <div className="minmax240 flex-row flex-center-start">
                                <BaseInput
                                  type="phone"
                                  readonly={
                                    contract?.contractStep === "APPLY_CANCEL" ||
                                    contract?.contractStep === "TERMINATE_COMPLETE" ||
                                    contract?.contractStep === "APPLY_CONFIRM" ||
                                    contract?.contractStep === "USE_COMPLETE"
                                  }
                                  name={`billContact.contact${index}`}
                                  value={noti.contact !== undefined ? String(formatPhoneNumber(noti.contact!)) : ""}
                                  onChange={(value: string) => {
                                    const dump = _.cloneDeep(editNonTaxNotiList);

                                    const checker = dump.filter((talk: any, idx: number) => idx !== index && talk.contact === value);

                                    if (checker.length === 0) {
                                      const adds = dump?.map((talk: any, idx: number) => {
                                        if (_.isEqual(talk, noti)) {
                                          talk.errorTextContact = "";
                                          talk.contact = value;
                                          if (Number(noti.billContactId) !== 0) {
                                            talk.cmdType = "U";
                                          }
                                        }
                                        return talk;
                                      });
                                      setEditNonTaxNotiList(adds);
                                    } else {
                                      const adds = dump?.map((talk: any, idx: number) => {
                                        if (idx === index) {
                                          talk.errorTextContact = "이미 등록된 연락처 입니다.";
                                        }
                                        return talk;
                                      });
                                      setEditNonTaxNotiList(adds);
                                    }
                                  }}
                                  onBlur={() => {
                                    const dump = _.cloneDeep(editNonTaxNotiList);

                                    if (validatePhoneNumberReturnBoolean(noti.contact!) === false) {
                                      const adds = dump.map((talk: any, idx: number) => {
                                        if (idx === index) {
                                          talk.errorTextContact = "휴대폰 번호 형식이 아닙니다.";
                                          talk.contact = "";
                                        }
                                        return talk;
                                      });
                                      setEditNonTaxNotiList(adds);
                                    }
                                  }}
                                  errorText={noti.errorTextContact ? noti.errorTextContact : ""}
                                />
                                {index === 0 && (
                                  <button
                                    disabled={editRentalSchedule?.isAutoSend === false}
                                    className="base-add-btn ml10"
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      const dump = _.cloneDeep(editNonTaxNotiList!);
                                      const filterd = dump.filter((bill: any) => bill.isDeleted === false);

                                      if (filterd && filterd.length < 5) {
                                        const anotherLine = {
                                          billContactId: 0,
                                          contractId: contract?.contractId,
                                          receiveNotiType: "TALK_OFFICIALDOC",
                                          contact: "",
                                          email: "",
                                          description: "",
                                          isDeleted: false,
                                          errorText: "",
                                          cmdType: "C",
                                        };

                                        dump.push(anotherLine);
                                        setEditNonTaxNotiList(dump);
                                      }
                                      return;
                                    }}
                                  />
                                )}
                                {index > 0 && (
                                  <button
                                    title="삭제"
                                    disabled={editRentalSchedule?.isAutoSend === false}
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      let dump = _.cloneDeep(editNonTaxNotiList);
                                      const finded = dump!.find((bill: any, idx: number) => idx === index && Number(bill.billContactId) === 0);

                                      if (finded) {
                                        dump = dump.filter((bill: any, idx: number) => idx !== index);
                                      } else {
                                        dump.forEach((bill: any, idx: number) => {
                                          if (idx === index && Number(bill.billContactId) !== 0) {
                                            bill.isDeleted = true;
                                            bill.cmdType = "D";
                                          }
                                        });
                                      }

                                      setEditNonTaxNotiList(dump);

                                      return;
                                    }}
                                    className="base-erase-btn size-large ml10"
                                  />
                                )}
                              </div>
                            </div>
                          </section>
                        </>
                      </div>
                    ),
                )}
              </div>
            }
          />
        )}
      </div>
      {detailData?.product?.productType !== "TIME_COURT" && editRentalSchedule?.isTaxInvoiceIssue && (
        <div className="flex-start">
          <BaseSection
            title={"세금계산서 수신자"}
            children={
              <div className="contents-container__grid-contents">
                <div className="">
                  {editTaxNotiList.map(
                    (noti: any, index: number) =>
                      noti.isDeleted === false && (
                        <div className="flex-row flex-center" key={index}>
                          <>
                            <section className="contents-container__grid">
                              <div className="contents-container__grid-index">
                                <p>이메일</p>
                              </div>
                              <div className="contents-container__grid-contents">
                                <div className="minmax240 flex-row flex-center-start">
                                  <BaseInput
                                    readonly={
                                      contract?.contractStep === "APPLY_CANCEL" ||
                                      contract?.contractStep === "TERMINATE_COMPLETE" ||
                                      contract?.contractStep === "APPLY_CONFIRM" ||
                                      contract?.contractStep === "USE_COMPLETE"
                                    }
                                    name={`billContact.email${index}`}
                                    value={noti.email}
                                    onChange={(value: string) => {
                                      const dump = _.cloneDeep(editTaxNotiList!);

                                      const checker = dump.filter((talk: any, idx: number) => idx !== index && talk.email === value);

                                      if (checker.length === 0) {
                                        const adds = dump.map((talk: any, idx: number) => {
                                          if (_.isEqual(talk, noti)) {
                                            talk.errorTextEmail = "";
                                            talk.email = value;
                                            if (Number(noti.billContactId) !== 0) {
                                              talk.cmdType = "U";
                                            }
                                          }
                                          return talk;
                                        });
                                        setEditTaxNotiList(adds);
                                      } else {
                                        const adds = dump.map((talk: any, idx: number) => {
                                          if (idx === index) {
                                            talk.errorTextEmail = "이미 등록된 연락처 입니다.";
                                          }
                                          return talk;
                                        });
                                        setEditTaxNotiList(adds);
                                      }
                                    }}
                                    onBlur={() => {
                                      const dump = _.cloneDeep(editTaxNotiList!);

                                      if (checkEmail.test(noti.email!) === false) {
                                        const adds = dump.map((talk: any, idx: number) => {
                                          if (idx === index) {
                                            talk.errorTextEmail = "이메일 형식이 아닙니다.";
                                            talk.email = "";
                                          }
                                          return talk;
                                        });
                                        setEditTaxNotiList(adds);
                                      }
                                    }}
                                    errorText={noti.errorTextEmail ? noti.errorTextEmail : ""}
                                  />
                                  {index === 0 && (
                                    <button
                                      disabled={editRentalSchedule?.regularPgcode === "virtualaccount"}
                                      className="base-add-btn ml10"
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        const dump = _.cloneDeep(editTaxNotiList);
                                        const filterd = dump.filter((bill: any) => bill.isDeleted === false);

                                        if (filterd.length < 3) {
                                          const anotherLine = {
                                            billContactId: 0,
                                            contractId: contract?.contractId,
                                            receiveNotiType: "TAX_INVOICE",
                                            contact: "",
                                            email: "",
                                            description: "",
                                            isDeleted: false,
                                            errorText: "",
                                            cmdType: "C",
                                          };

                                          dump.push(anotherLine);
                                          setEditTaxNotiList(dump);
                                        }
                                        return;
                                      }}
                                    />
                                  )}
                                  {index > 0 && (
                                    <button
                                      title="삭제"
                                      disabled={editRentalSchedule?.regularPgcode === "virtualaccount"}
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        let dump = _.cloneDeep(editTaxNotiList);
                                        const finded = dump.find((bill: any, idx: number) => idx === index && Number(bill.billContactId) === 0);
                                        console.log("finded", finded);
                                        if (finded) {
                                          dump = dump.filter((bill: any, idx: number) => idx !== index);
                                        } else {
                                          dump.forEach((bill: any, idx: number) => {
                                            if (idx === index && Number(bill.billContactId) !== 0) {
                                              bill.isDeleted = true;
                                              bill.cmdType = "D";
                                            }
                                          });
                                        }
                                        setEditTaxNotiList(dump);

                                        return;
                                      }}
                                      className="base-erase-btn size-large ml10"
                                    />
                                  )}
                                </div>
                              </div>
                            </section>
                          </>
                        </div>
                      ),
                  )}
                </div>
              </div>
            }
          />
        </div>
      )}
    </>
  );
};
export default ContractInfoEdit;
