import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { editScheduleRoundList, getScheduleRoundList } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { useToast } from "src/recoil/toast/hook";
import Accordion from "./components/Accordion";

type Props = {
  scheduleId: number;
  detailData: any;
  isOpen: boolean;
  viewOnly: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  setPopupState?: any;
  setCommonModals?: any;
  setEditConfrim?: any;
};

type BillDetail = {
  adjustAmount: number;
  baseAmount: number;
  billId: string;
  dtlId: string;
  dtlOrder: number;
  isDeleted: boolean;
  itemName: string;
  remark: string;
  supplyAmount: number;
  taxAmount: number;
  totalAmount: number;
  trDateTime: string;
};

type UpdateSchedulesBill = {
  billId: string;
  pgcode?: string;
  billDueDate?: string;
  billDetailList?: Array<BillDetail>;
};

const ModifyAmountModalAccordian = (props: Props) => {
  const { openToast } = useToast();
  const [original, setOriginal] = useState<Array<UpdateSchedulesBill>>([]);
  const [data, setData] = useState<any>([]);
  const [dataEtc, setDataEtc] = useState<any>([]);
  const [confirm, setConfirm] = useState(false);
  const [suc, setSuc] = useState(false);

  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundList);
  //회차별 할인 수정
  const { executeAsync: updateRoundList } = useApiOperation(editScheduleRoundList);

  const sendDataMaker = (dt: UpdateSchedulesBill) => {
    const send: UpdateSchedulesBill = {
      billId: dt.billId,
      pgcode: dt.pgcode,
      billDueDate: dt.billDueDate,
      billDetailList: dt.billDetailList,
    };
    return send;
  };

  const upadteSchedules = useCallback(async () => {
    const sendData: Array<UpdateSchedulesBill> = [];
    const deleteViewflag: Array<UpdateSchedulesBill> = _.cloneDeep(data);
    deleteViewflag.forEach((sort: any) => delete sort.isView);

    deleteViewflag.forEach((dt: UpdateSchedulesBill) => {
      const checkInnerArr: any = [];
      const fnd = original.find((oriBill: any) => oriBill.billId === dt.billId);

      //다른 경우
      if (!_.isEqual(dt.billDetailList!, fnd!.billDetailList!)) {
        dt.billDetailList!.forEach((btdl: BillDetail) => {
          const checker = fnd!.billDetailList!.find((ftdl: BillDetail) => btdl.dtlId === ftdl.dtlId);
          if (checker === undefined || !_.isEqual(btdl, checker)) {
            checkInnerArr.push(btdl);
          }
        });
        dt.billDetailList = checkInnerArr;

        sendData.push(sendDataMaker(dt));
      } else {
        if (!_.isEqual(dt!, fnd!)) {
          dt.billDetailList = [];
          sendData.push(sendDataMaker(dt));
        }
      }
    });
    // console.log("전송 데이터 검증 sendData", sendData);
    if (sendData.length === 0) {
      openToast({ content: "잔송할 데이터가 없습니다.", isSystemToast: true });
      return;
    }

    const res: any = await updateRoundList({ scheduleId: props.scheduleId, billList: sendData });
    if (res.status > 199 && res.status < 300) {
      props.setCommonModals({
        message: "저장 되었습니다.",
        title: "회차별 금액 변경",
      });
      props.setPopupState(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, original, props.scheduleId, updateRoundList]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkSaveData = (data: Array<UpdateSchedulesBill>) => {
    const deleteViewflag: Array<UpdateSchedulesBill> = _.cloneDeep(data);
    let error = { content: "", isSystemToast: true, validation: true };
    for (let i = 0; i < deleteViewflag.length; i++) {
      const dt = deleteViewflag[i];
      if ((dt.billDueDate === undefined || dt.billDueDate) === "" || dt.billDueDate === null) {
        error = { content: "납부기한은 필수 입력 항목입니다.", isSystemToast: true, validation: false };
        break;
      }

      for (let j = 0; j < dt.billDetailList!.length; j++) {
        const detailListItem = dt.billDetailList![j];
        if (detailListItem.itemName === "") {
          error = { content: "항목명 상세는 필수 입력 항목입니다.", isSystemToast: true, validation: false };
          break;
        }

        if (Number(detailListItem.baseAmount) < 0) {
          error = { content: "판매가액은 필수 입력 항목입니다.", isSystemToast: true, validation: false };
          break;
        }

        if (Number(detailListItem.baseAmount) + Number(detailListItem.adjustAmount) < 0) {
          error = { content: "할인/할증 금액과 판매가액의 합은 0보다 작을 수 없습니다.", isSystemToast: true, validation: false };
          break;
        }
      }
    }

    if (!error.validation) {
      openToast({ content: error.content, isSystemToast: true });
      return false;
    }
    return true;
  };
  const saveBtn = useCallback(() => {
    const validation: boolean = checkSaveData(data);
    // console.log("validation", validation);
    if (validation) {
      setConfirm(true);
    }
  }, [checkSaveData, data]);

  const confirmBtn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      upadteSchedules();
      setConfirm(false);
      if (props.onClick) return props.onClick(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, upadteSchedules],
  );

  const callScheduleList = useCallback(
    async (scheduleId: number) => {
      setData([]);
      const response: any = await getRoundList({ scheduleId: Number(scheduleId), isAll: true });
      if (response.status > 199 && response.status < 300) {
        // console.log("response.data.data.content", response.data.data.content);
        let sorted = _.sortBy(response.data.data.content.bill, ["billOrder"]);
        setOriginal(_.cloneDeep(sorted));
        sorted.forEach((sort: any) => (sort.isView = false));
        setData(sorted);

        setDataEtc(response.data.data.content.billEtc);
      }
    },
    [getRoundList],
  );

  useEffect(() => {
    if (props.isOpen === true) {
      callScheduleList(props.scheduleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <BaseAbstractModal size="xlarge" isOpen={props.isOpen}>
      <>
        <Accordion data={data} setData={setData} viewOnly={props.viewOnly} spaceProductType={props.detailData?.product?.productType} />
        <section className="base-abstract-modal__btn-wrap">
          {!props.viewOnly && (
            <>
              <BaseButton title={"닫기"} className="color-white" onClick={props.onClose} />
              <BaseButton title={"저장"} onClick={() => saveBtn()} />
            </>
          )}
          {props.viewOnly && (
            <>
              <BaseButton title={"닫기"} onClick={props.onClose} />
            </>
          )}
        </section>
        {confirm && (
          <BaseModal
            isOpen={confirm}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => confirmBtn(e)}
            onClose={() => {
              setConfirm(false);
            }}
          >
            <p>변경된 금액으로 빌링스케줄을 저장하시겠습니까?</p>
          </BaseModal>
        )}
        {suc && (
          <BaseModal
            isOpen={suc}
            btnRightTitle="확인"
            onClick={() => {
              setSuc(false);
            }}
          >
            <p>저장되었습니다.</p>
          </BaseModal>
        )}
      </>
    </BaseAbstractModal>
  );
};

export default ModifyAmountModalAccordian;
