import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useTable } from "react-table";
import { deleteContractAccessAsync, getContractAccessGroupAsync, postContractAccessAsync } from "src/api/access/contractac-api";
import { ContractAccessModel, ContractAccessUpdateData } from "src/api/access/contractac-types";
import { getAccessGroupListAsync } from "src/api/access/productac-api";
import { Ac2ProductAccessGroupModel } from "src/api/access/productac-types";
import { DetailTotalData } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseConfirmModal } from "src/components";
import { getStatusMessage } from "src/components/ErrorModal";
import GoToListButton from "src/components/GoToListButton";
import { Modal } from "src/pages/building/building-types";
import SearchAccessLog from "src/pages/commonPopup/SearchAccessLog";
import AccessGroupDetailModal from "src/pages/product/components/AccessGroupDetailModal";
import UserAndVisitorAccess from "./components/UserAndVisitorAccess";
import SelectContractAccessGroupModal from "./modals/SelectContractAccessGroupModal";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
}

type ErrorData = {
  error: { errorCode: string; errorMessage: string; status: number };
};

const ContractAccessGroup = ({ detailData }: ContractBasicProp) => {
  const { isAuthority } = usePartnerAuthority();

  // 로딩바
  const columns: any[] = useMemo(
    () => [
      {
        Header: "출입그룹 id",
        accessor: "accessGroupId",
        width: 140,
      },
      {
        Header: "출입 그룹명",
        accessor: "accessGroupName",
        width: 510,
        Cell: ({ row, value }: Cell<ContractAccessModel & ErrorData>) => {
          const error = row?.original?.error;
          const errorMessage = error?.status && getStatusMessage(error?.status);
          const errorCode = error?.errorCode;
          return (
            <div className="w-100 text-left">
              <p>{value}</p>
              {errorMessage && <p className="font12 text-red mt3">{`${errorMessage} ${errorCode && `[${errorCode}]`}`}</p>}
            </div>
          );
        },
      },
      {
        Header: "방문자 허용",
        accessor: "visitorAccessYn",
        width: 140,
        Cell: ({ value }: Cell<ContractAccessModel>) => {
          if (value === true) {
            return "허용";
          } else {
            return "미허용";
          }
        },
      },
      {
        Header: "출입장치",
        accessor: "accessDeviceView",
        width: 280,
        Cell: ({ row }: Cell<ContractAccessModel>) => {
          return (
            <div
              className="flex-center-center"
              onClick={() => {
                setAccessGroupId(Number(row.original.accessGroupId));
                setShowDetailModal({ isOpen: true, payload: row.original.accessGroupName });
              }}
            >
              <span className="cursor-pointer text-underline text-blue900">보기</span>
            </div>
          );
        },
      },
    ],
    [],
  );
  const etc: any[] = useMemo(
    () => [
      {
        Header: "비고",
        accessor: "etc",
        width: 140,
        Cell: ({ row }: Cell<ContractAccessModel>) => {
          return row.original.contractManageId ? (
            <button
              className="base-trash-btn color-gray"
              onClick={(e) => {
                e.preventDefault();
                setConfirmModal({
                  isOpen: true,
                  message: "삭제하시겠습니까?",
                  type: "remove",
                  payload: { ctAccessGroupId: row.original.ctAccessGroupId, contractManageId: row.original.contractManageId },
                });
              }}
            ></button>
          ) : (
            <span className="text-red">실패</span>
          );
        },
      },
    ],

    [],
  );

  // 계약 출입그룹 등록된 목록
  const [contractAccess, setContractAccess] = useState<ContractAccessModel[]>([]);

  const [accessList, setAccessList] = useState<Ac2ProductAccessGroupModel[]>([]);

  // 출입그룹 선택 팝업
  const [showSelectModal, setShowSelectModal] = useState(false);

  // 출입기록 조회 팝업
  const [accessRecordModal, setAccessRecordModal] = useState(false);

  // 상세정보 팝업
  const [showDetailModal, setShowDetailModal] = useState<Modal>({ isOpen: false });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  const contractManageId = useMemo(() => Number(detailData?.contract?.contractManageId), [detailData]);

  const contractApplyNumber = useMemo(() => detailData?.contractManage?.contractApplyNumber, [detailData]);

  const contractId = useMemo(() => detailData?.contract?.contractId, [detailData]);

  //
  // contractAccess 에 accessList에 있는 출입그룹명과 방문자 허용 데이터 추가
  const contractAcList = useMemo(() => {
    return contractAccess?.map((ac) => {
      accessList.map((item) => {
        if (Number(ac.accessGroupId) === Number(item.id)) {
          ac = { ...ac, accessGroupName: item.accessGroupName, visitorAccessYn: item.visitorAccessYn };
        }
        return ac;
      });
      return ac;
    });
  }, [accessList, contractAccess]);
  //
  const checkAuth = useMemo(() => {
    let cols: any[] = columns;
    // 출입그룹 관리 > 테이블 > 삭제 아이콘 처리 > "d" 권한이 있으면 노출
    if (isAuthority("CONTRACT", "d")) {
      cols[cols.length - 1].width = 140;
      cols = cols.concat(etc);
    }
    return cols;
  }, [columns, etc, isAuthority]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    { data: contractAcList || [], columns: checkAuth },
    useBlockLayout,
  );

  // 공간상품 출입그룹 목록
  const { executeAsync: getContractAccess } = useApiOperation(getContractAccessGroupAsync);

  //팝업 출입그룹 목록
  const { executeAsync: getAccessList } = useApiOperation(getAccessGroupListAsync);

  // 출입그룹 등록
  const { executeAsync: postContractAccess } = useApiOperation(postContractAccessAsync, {
    noHandleError: true,
  });

  // 출입그룹 삭제
  const { executeAsync: deleteContractAccess } = useApiOperation(deleteContractAccessAsync);

  // 공간상품 출입그룹 목록 api
  const fetchContractAccess = useCallback(
    async (id: number) => {
      const { data } = await getContractAccess({ contractManageId: Number(id) });
      if (data.data) {
        setContractAccess(data.data.accessGroup);
        const ids = data.data.accessGroup?.map((item) => String(item.accessGroupId)) as string[];
        if (ids.length > 0) {
          const accessResponse = await getAccessList({ ids: ids.join(",") });
          if (accessResponse.status >= 200 && accessResponse.status <= 299) {
            setAccessList(accessResponse.data.data.content);
          }
        }
      }
    },
    [getAccessList, getContractAccess],
  );
  useEffect(() => {
    if (contractManageId) {
      fetchContractAccess(Number(contractManageId));
    }
  }, [fetchContractAccess, contractManageId]);

  // 출입그룹 등록완료 / 실패 확인 모달
  const openAccessCompletePopup = (list: any) => {
    // 100% 성공시
    const allCompleted = list.every((item: any) => !item.error);
    const allFailed = list.every((item: any) => item.error);

    if (allCompleted) {
      setAlertModal({ isOpen: true, title: "출입그룹이 적용되었습니다.", message: "추가된 출입그룹에 바로 엑세스 할 수 있습니다" });
    }

    if (allFailed) {
      setAlertModal({ isOpen: true, title: "출입그룹 추가에 실패하였습니다.", message: "목록에서 사유를 확인해 주세요" });
    }

    // 부분 성공 및 실패 일시
    if (!allCompleted && !allFailed) {
      setAlertModal({ isOpen: true, title: "출입그룹이 적용되었습니다.", message: "추가된 출입그룹에 바로 엑세스 할 수 있습니다", type: "some" });
    }
  };

  // 등록
  const onAddProductAccess = useCallback(
    async (accessGroup: Ac2ProductAccessGroupModel[]) => {
      const promises = accessGroup.map(async (access, idx) => {
        const postData: ContractAccessUpdateData = {
          accessGroupId: Number(access.id) || 0,
          contractManageId: contractManageId || 0, //성공
        };
        let response;
        try {
          response = await postContractAccess(postData);
          const result = response.data.data;
          //  RAD21. 출입그룹 페이지 조회  api 호출 - 출입그룹명을 추가히기 위함 (accessGroupName)
          const accessResponse = await getAccessList({ ids: String(result.accessGroupId) });
          if (accessResponse.data.data.content) {
            const findAccessGroup = accessResponse.data.data.content.find((item) => Number(item.id) === Number(result.accessGroupId));
            return {
              data: {
                ...result,
                accessGroupName: findAccessGroup?.accessGroupName, //
                accessGroupId: result.accessGroupId,
                visitorAccessYn: findAccessGroup?.visitorAccessYn,
              },
              error: null,
            };
          }
          // return { data: result, error: null };
        } catch (error) {
          const errorData = {
            errorCode: response?.data?.meta?.errorCode,
            status: response?.status,
          };
          return { data: null, error: { ...access, errorData } };
        }
      });
      Promise.all(promises).then(async (results) => {
        const merged = results.map((item) => {
          let fileData;
          if (item?.error) {
            fileData = {
              accessGroupName: item?.error.accessGroupName || "",
              accessGroupId: Number(item?.error.id),
              visitorAccessYn: item?.error.visitorAccessYn || false,
              error: item?.error.errorData,
            };
          }
          return { ...item?.data, ...fileData };
        });
        console.log(`merged`, merged);
        openAccessCompletePopup(merged);
        setContractAccess((prev) => [...prev, ...merged]);
      });
    },
    [contractManageId, getAccessList, postContractAccess],
  );

  // 출입그룹 선택시 (선택 시 저장)
  const onSelectedAccessGroups = useCallback(
    (selectedItems: Ac2ProductAccessGroupModel[]) => {
      if (selectedItems.length > 0) {
        onAddProductAccess(selectedItems);
      }
      setShowSelectModal(false);
    },
    [onAddProductAccess],
  );

  // 출입그룹 삭제시
  const onRemoveAccessGroup = useCallback(
    async (deleteData: { ctAccessGroupId: number; contractManageId: number }) => {
      const response = await deleteContractAccess(deleteData);
      if (response.status >= 200 && response.status <= 299) {
        await fetchContractAccess(Number(contractManageId));
      }
    },
    [contractManageId, deleteContractAccess, fetchContractAccess],
  );

  const clickConfirmModal = useCallback(() => {
    if (confirmModal.type === "remove") {
      onRemoveAccessGroup(confirmModal.payload);
    }
  }, [confirmModal, onRemoveAccessGroup]);

  // 출입그룹 상세 키값
  const [accessGroupId, setAccessGroupId] = useState(0);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap detail-usage-restriction">
          <article className="contents-container__wrap-article">
            {/* 이용자 관리 / 방문자 관리 */}
            <UserAndVisitorAccess
              contractApplyNumber={String(contractApplyNumber)}
              detailData={detailData}
            />
          </article>
          <article className="contents-container__wrap-article">
            <div className="contents-container__sub-title mb0">
              <div className="flex-center-between w-100">
                <div className=" pb4">
                  <h2 className="font18">출입그룹 관리</h2>
                </div>
                <div className="contents-container__grid-contents flex-center">
                  <div className=" mr10">
                    <BaseButton
                      title="출입기록 조회"
                      className="color-white"
                      onClick={() => {
                        setAccessRecordModal(true);
                      }}
                    />
                    {accessRecordModal && (
                      <SearchAccessLog //
                        isOpen={true}
                        contractManageId={String(contractManageId)}
                        contractApplyNumber={String(contractApplyNumber)}
                        onClose={() => setAccessRecordModal(false)}
                      />
                    )}
                  </div>
                  <div className="">
                    {isAuthority("CONTRACT", "w") && (
                      <BaseButton
                        title="+ 출입그룹 추가"
                        onClick={() => {
                          setShowSelectModal(true);
                        }}
                      />
                    )}
                    {showSelectModal && (
                      <SelectContractAccessGroupModal
                        setShowModal={setShowSelectModal}
                        onSelectedAccessGroups={onSelectedAccessGroups}
                        access={contractAccess || []}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <section>
              <section className="inner-tab-table">
                <div className="my20">
                  <div {...getTableProps()} className="base-table sticky">
                    <div className="header">
                      {headerGroups.map((headerGroup) => (
                        <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                          {headerGroup.headers.map((header) => {
                            return (
                              <div {...header.getHeaderProps()} className="base-table__th">
                                {header.render("Header")}
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                    <div {...getTableBodyProps()} className="body">
                      {rows.map((row: any) => {
                        prepareRow(row);
                        return (
                          <div {...row.getRowProps()} className={`base-table__tr`}>
                            {row.cells.map((cell: any) => {
                              return (
                                <div {...cell.getCellProps()} className="base-table__td">
                                  {cell.render("Cell")}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                    {rows.length === 0 && (
                      <div className="base-table__tr table-cursor-unset">
                        <div className="base-table__td w-100 text-center">
                          <div className="w-100">
                            <span>데이터가 없습니다.</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </section>
          </article>

          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          {confirmModal.isOpen && (
            <BaseConfirmModal
              isOpen={true}
              btnLeftTitle="취소"
              btnRightTitle="확인"
              onClose={() => setConfirmModal({ isOpen: false })}
              onClick={() => {
                clickConfirmModal();
                setConfirmModal({ isOpen: false });
              }}
              className="px30"
            >
              <div>
                <h2 className="mb20">해당 출입그룹을 삭제합니다</h2>
                <p className=" text-gray600">삭제한 즉시 해당 출입그룹에 액세스 할 수 없습니다</p>
              </div>
              {/* <p>{confirmModal.message}</p> */}
            </BaseConfirmModal>
          )}

          {/* 확인버튼만 있는 alert 모달 */}
          {alertModal.isOpen && (
            <BaseConfirmModal
              isOpen={true}
              btnRightTitle="확인"
              onClick={() => setAlertModal({ isOpen: false })}
              title={alertModal.title}
              className="pa30"
            >
              {/* <h2 className="mb30">{alertModal.title}</h2> */}
              <p className="lh-20 mt20 text-gray600">
                {alertModal.message}
                <br />
                {alertModal.type === "some" && (
                  <span className="text-red">
                    *일부 그룹 추가에 실패 하였습니다.
                    <br />
                    목록에서 사유를 확인해 주세요
                  </span>
                )}
              </p>
            </BaseConfirmModal>
          )}

          {showDetailModal.isOpen && (
            <AccessGroupDetailModal showModal={showDetailModal} setShowModal={setShowDetailModal} accessGroupId={accessGroupId} />
          )}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area"></div>
      </div>
    </>
  );
};

export default ContractAccessGroup;
