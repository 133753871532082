import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { getContractApply } from "src/api/contract/contract-api";
import { SearchParams } from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { PageMeta, contract_bill_user_errors } from "src/api/public-types";
import { BasePagination } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useApiLoading from "src/hooks/useApiLoading";
import useNavigate from "src/hooks/usePartnerNavigate";
import useSpaceProductColumns from "../../columns/UseSpaceProduct";

type Props = {
  orgId: string;
};

const UseProductInfo = ({ orgId }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { id } = useParams();
  const [data, setData] = useState<any>([]);
  // const classname = "minmax300";

  const [pageMeta, setPateMeta] = useState<PageMeta>();
  const [pageNum, setPageNum] = useState(0);
  const { isApiLoading } = useApiLoading();
  const queryParams: SearchParams = useMemo(() => {
    const _queryParams: SearchParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.searchType) {
      _queryParams.searchType = "MB_ORGANIZATION_ID";
    }
    if (!_queryParams?.searchValue) {
      _queryParams.searchValue = orgId;
    }

    return _queryParams;
  }, [location, orgId]);

  //호출부
  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: useSpaceProductColumns,
      data,
      disableSortBy: true,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  // 신청/계약 목록 api
  const callList = useCallback(
    async (passParams: SearchParams) => {
      // const params: SearchParams = {};
      // params.searchValue = orgId;
      // params.searchType = "MB_ORGANIZATION_ID";
      // params.searchValue = decodeURIComponent(params.searchValue || "");

      let response: any = await getContractList(passParams);

      if (response && response.status >= 200 && response.status <= 299) {
        const sorted: any = _.sortBy(response.data.data.content, ["contractStartTime", "contractManageId"]).reverse();
        setData(sorted);
        setPateMeta(response.data.meta.pageMeta);
      }
    },
    [getContractList],
  );

  useEffect(() => {
    if (data.length === 0) {
      callList(queryParams);
    }
  }, [callList, data.length, queryParams]);

  // 사업자 신청/계약 탭 페이지네이션
  const onPagination = async (page: number) => {
    const pageParams = { ...queryParams, page };
    // const page = pageParams.page;
    setPageNum(Number(page));
    await callList(pageParams);
  };

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap detail-basic-info">
          <article className="contents-container__wrap-article">
            <section className="state-change-info inner-tab-table">
              <div>
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header) => {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              {header.render("Header")}
                              {/* <div className="ic_sort"></div> */}
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {rows.map((row: any) => {
                      prepareRow(row);

                      return (
                        <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                    {rows.length === 0 && (
                      <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                        <div className="base-table__td w-100 text-center">
                          <div className="w-100">{!isApiLoading() && <span>신청/계약 내역이 없습니다.</span>}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <BasePagination
                pageIndex={pageNum || 0}
                totalPages={Number(pageMeta?.totalPages || 0)}
                totalElements={Number(pageMeta?.totalElements || 0)}
                goPage={(pageIndex: number) => {
                  onPagination(Number(pageIndex));
                }}
              />
            </section>
          </article>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area"></div>
      </div>
    </>
  );
};

export default UseProductInfo;
