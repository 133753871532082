import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync } from "src/api/product/product-api";
import { deleteProductGuideAsync, getProductGuideListAysnc } from "src/api/product/product-guide-api";
import { ProductGuideListRequest, ProductGuideListType, ProductGuideType, ProductModel } from "src/api/product/product-types";
import { BaseButton, BaseModal, BaseSelect, ContentsIdSection } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";

import { Modal } from "../../product-types";
import ProductGuideTypeDetail from "./components/ProductGuideTypeDetail";
import ProductGuideTypeList from "./components/ProductGuideTypeList";
import { BaseTooltip } from "src/components";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

/* 
  공간상품 > 상세 > 안내
*/
const GuideDetail = ({ productDetail }: { productDetail: ProductModel }) => {
  const { isAuthority } = usePartnerAuthority();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const courtUrl = useMemo(() => {
    return process.env.REACT_APP_COURT_BASSEURL;
  }, []);

  // 등록된 이용안내 리스트
  const [guideList, setGuideList] = useState<Array<ProductGuideListType>>([]);

  // 공간상품 상세
  const [product, setProduct] = useState<ProductModel | null>(null);

  // 건물 대표 id
  const [isBuildingPrimaryId, setIsBuildingPrimaryId] = useState("");

  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false });

  // 공간상품 사용안내 삭제

  const { executeAsync: deleteProductGuide } = useApiOperation(deleteProductGuideAsync);

  // 공간상품 이용안내 id 추출
  const guideId = useMemo(() => {
    return queryParams.guideId && String(queryParams.guideId);
  }, [queryParams.guideId]);

  // 공간상품 이용안내 타입 추출
  const productGuideType = useMemo(() => {
    const currentProductGuideType = queryParams.productGuideType as ProductGuideType;
    return currentProductGuideType || "PRODUCT_GUIDE_UNRECOGNIZED";
  }, [queryParams.productGuideType]);

  // path variable 에서 productId 추출
  const productId = useMemo(() => {
    const paths = location.pathname.split("/");
    const pathProductId = paths[paths.length - 1];
    return Number(pathProductId);
  }, [location]);

  // 건물 선택옵션
  const buildingOptions = useMemo(() => {
    const options = product?.buildingList?.map((building: BuildingModel) => {
      return {
        value: String(building.id),
        label: String(building.buildingName),
      };
    });
    return options;
  }, [product?.buildingList]);

  // 공간상품 상세 조회 api
  const { executeAsync: getProduct } = useApiOperation(getProductDetailAsync);

  // 공간상품 사용안내 리스트 api
  const { executeAsync: getProductGuideList } = useApiOperation(getProductGuideListAysnc);

  // 이용안내 리스트 가져오기 - 건물 select id 가져오면서 호출 (guideBuildingPrimaryId)
  const getProductGuideListApi = useCallback(
    async (productId: number, buildingId: number) => {
      const queryData = {
        buildingId,
        isDisplayed: false,
      };

      const guideParamData: ProductGuideListRequest = {
        productId,
        query: queryData,
      };

      const response = await getProductGuideList(guideParamData);

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content.sort((a, b) => Number(b?.orderNums) - Number(a?.orderNums));
        setGuideList(result);
      }
    },
    [getProductGuideList],
  );

  // 건물 리스트 select setState
  const guideBuildingPrimaryId = useCallback(
    (buildings: Array<BuildingModel>) => {
      // 대표인 건물 찾기 (건물 내 층/호실에 isPrimary 값이 있다)

      let isPrimaryId = "";

      buildings?.forEach((building: BuildingModel) => {
        const floors = building.buildingFloorList; // 건물 층 리스트
        floors?.forEach((floor) => {
          const rooms = floor.buildingRoomList; // 건물 층 안에 호실 리스트
          const buildingId = building.id;

          // isPrimary 여부 확인
          const isPrimary = rooms?.some((room) => room.isPrimary === true);
          if (isPrimary) {
            setIsBuildingPrimaryId(String(buildingId));
            isPrimaryId = String(buildingId);
            return isPrimaryId;
          } else {
            // isPrimary 가 없으면 첫번째 빌딩 노출
            const firstBuildingId = buildings[0].id;
            setIsBuildingPrimaryId(String(firstBuildingId));
            isPrimaryId = String(firstBuildingId);
            return isPrimaryId;
          }

          // url에 builidngId 가 있으면 setValue - form 영역 진입시 선택한 건물 유지 필요
        });
      });

      if (queryParams.buildingId) {
        setIsBuildingPrimaryId(String(queryParams.buildingId));
      }

      // getProductGuideListApi(Number(productId), Number(isPrimaryId));
    },
    [queryParams.buildingId],
  );

  // 공간상품 상세조회 후 데이터 바인딩
  const fetchProduct = useCallback(
    async (productId: number) => {
      if (!productDetail) return;

      setProduct(null);
      const buildings = productDetail?.buildingList;

      guideBuildingPrimaryId(buildings || []);
      setProduct(productDetail || null);
    },
    [guideBuildingPrimaryId, productDetail],
  );

  // 공간상품 이용안내 삭제 api
  const deleteProductGuideApi = async (deleteId: string) => {
    if (deleteId) {
      const response = await deleteProductGuide({
        productId: Number(productId),
        ids: [deleteId],
      });

      if (response.status >= 200 && response.status <= 299) {
        navigate(`${PagePath.product.detail.replace(":id", String(productId))}?tab=guide&buildingId=${isBuildingPrimaryId}`);
      }
    }
  };

  useEffect(() => {
    if (!productDetail) return;
    fetchProduct(productId);
  }, [productId, fetchProduct, productDetail]);

  useEffect(() => {
    if (productId && isBuildingPrimaryId) getProductGuideListApi(Number(productId), Number(isBuildingPrimaryId));
  }, [getProductGuideListApi, isBuildingPrimaryId, productId, location]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-article">
            <article className="contents-container__1200">
              <section className="contents-container__grid contents-container__1200">
                <div className="contents-container__grid-index">
                  <p>URL</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <div className="font14">
                      <a
                        className="text-underline"
                        target={"_blank"}
                        href={`${courtUrl}/front/court/product/${productId}/public/guides`}
                        rel="noreferrer"
                      >
                        {`${courtUrl}/front/court/product/${productId}/public/guides`}
                        <span className="ic-target-blank"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid contents-container__1200">
                <div className="contents-container__grid-index flex-start">
                  <p className="mr4">문의채널</p>
                  <BaseTooltip touchIcon={"QUESTION"} className="mt1" size={16} tooltip="전시정보 탭에서 수정할수 있습니다." />
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <div className="font14">
                      <a className="text-underline" target={"_blank"} href={`${product?.vocUrl}`} rel="noreferrer">
                        {product?.vocUrl}
                        <span className="ic-target-blank"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              {buildingOptions?.length === 0 ? (
                <section className="contents-container__grid contents-container__1200">
                  <div className="contents-container__grid-index">
                    <p>건물</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <div className="minmax140">
                        <p>데이터가 없습니다.</p>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <>
                  <section className="contents-container__grid contents-container__1200 ">
                    <div className="contents-container__grid-index">
                      <p>건물</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-row flex-center-start">
                        <div className="minmax140">
                          <BaseSelect
                            className="minmax200"
                            isDisabled={(buildingOptions?.length === 1 && true) || !!queryParams.guideId}
                            value={isBuildingPrimaryId}
                            stateOptions={buildingOptions || []}
                            setStateValue={(value: string) => {
                              const guideDetailPath = `${PagePath.product.detail.replace(":id", String(productId))}?tab=guide&buildingId=${value}
                          `;
                              navigate(guideDetailPath);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    {/* {!queryParams.productGuideType ? ( */}
                    {!queryParams.guideId ? (
                      //건물 select box & 이용안내 list &  +항목추가, +네트워크 추가, +주차장 추가 영역
                      <ProductGuideTypeList guideList={guideList} buildingId={isBuildingPrimaryId} productId={productId} />
                    ) : (
                      // 공간상품 이용안내 항목별 상세 페이지
                      <ProductGuideTypeDetail productGuideType={productGuideType} guideId={guideId || ""} />
                    )}
                  </section>
                </>
              )}
            </article>
          </div>

          <BaseModal
            isOpen={deleteModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => {
              deleteProductGuideApi(String(guideId) || "");
              setDeleteModal({ isOpen: false });
            }}
            onClose={() => setDeleteModal({ isOpen: false })}
          >
            <p>{deleteModal.message}</p>
          </BaseModal>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          {guideId && (
            <>
              {isAuthority("PRODUCT", "d") && (
                <BaseButton
                  title="삭제"
                  className="color-white size-large mr10"
                  onClick={() => {
                    setDeleteModal({ isOpen: true, message: "삭제하시겠습니까?" });
                  }}
                />
              )}
              {isAuthority("PRODUCT", "w") && (
                <BaseButton
                  title="수정"
                  className="size-large"
                  onClick={() => {
                    const formPath = `${PagePath.product.form}?id=${productId}&tab=guide&buildingId=${isBuildingPrimaryId}&guideId=${guideId}&productGuideType=${queryParams.productGuideType}`;
                    navigate(formPath);
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default GuideDetail;
