import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import {
  eidtProductGuideAsync,
  fetchProductGuideDetailAsync,
  getProductGuideListAysnc,
  postProductGuideAsyncNew,
} from "src/api/product/product-guide-api";
import {
  NewProductGuide,
  ProductGuideListRequest,
  ProductGuideListType,
  ProductGuideNetworkCatalog,
  ProductGuideType,
  ProductGuideTypes,
  ProductGuideUpdateForm,
  ProductModel,
} from "src/api/product/product-types";
import { BaseButton, BaseModal, BaseSelect, ContentsIdSection } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";

import { PagePath } from "../../details";
import ProductGuideTypeList from "../../details/guide/components/ProductGuideTypeList";
import GuideUploadForms from "./components/GuideUploadForms";
import { Modal } from "./guide-types";
import { BaseTooltip } from "src/components";

interface IProps {
  product: ProductModel;
}
//
// NEW 공간상품 이용안내
const GuideForm = ({ product: productDetail }: IProps) => {
  // 로딩바

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const courtUrl = useMemo(() => {
    return process.env.REACT_APP_COURT_BASSEURL;
  }, []);

  // path variable 에서 productId 추출
  const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 공간상품 상세
  const [product, setProduct] = useState<ProductModel | null>(null);

  // 공간상품 이용안내 리스트
  const [guideList, setGuideList] = useState<Array<ProductGuideListType>>([]);

  // 등록/수정 시 전달할 react-form Data
  const [submitData, setSubmitData] = useState<ProductGuideUpdateForm>();

  // 공간상품 이용안내 상세
  // const [guideDetail, setGuideDetail] = useState<NewProductGuide>();

  // 건물 선택옵션
  const buildingOptions = useMemo(() => {
    const options = product?.buildingList?.map((building: BuildingModel) => {
      return {
        value: String(building.id),
        label: String(building.buildingName),
      };
    });
    return options;
  }, [product?.buildingList]);

  // 건물 대표 id
  const [isBuildingPrimaryId, setIsBuildingPrimaryId] = useState("");

  // 공간상품 (전체) 상세 조회 api
  // const { executeAsync: getProduct } = useApiOperation(getProductDetailAsync);

  // 공간상품 이용안내 상세 조회
  const { executeAsync: fetchProductGuide } = useApiOperation(fetchProductGuideDetailAsync);

  // 공간상품 사용안내 리스트 api
  const { executeAsync: getProductGuideList } = useApiOperation(getProductGuideListAysnc);

  // 공간상품 사용안내 등록
  const { executeAsync: postProductGuide } = useApiOperation(postProductGuideAsyncNew);

  // 공간상품 사용안내 수정
  const { executeAsync: editProductGuide } = useApiOperation(eidtProductGuideAsync);

  // queryParm에 있는 productGuideType 가져와서 바인딩
  const productGuideType = useMemo(() => {
    const currentProductGuideType = queryParams.productGuideType as ProductGuideType;
    return currentProductGuideType || "PRODUCT_GUIDE_UNRECOGNIZED";
  }, [queryParams.productGuideType]);

  const guideId = useMemo(() => {
    return queryParams.guideId && String(queryParams.guideId);
  }, [queryParams.guideId]);
  //
  // hook-form default 값
  const defaultValues = useMemo(() => {
    const productGuidePost: ProductGuideUpdateForm = {
      productGuide: {
        subject:
          productGuideType === ProductGuideTypes.PARKINGLOT2
            ? "주차 관련 안내"
            : productGuideType === ProductGuideTypes.GUIDE_WIFI
            ? "네트워크 연결"
            : "", //항목명
        productGuideType, //이용안내 타입
        driverUrl: "", //복합기 드라이버 다운로드 주소
        description: "", //상세내용 (에디터 area)
        buildingId: "",
        customerWifiList: [
          {
            isOpened: false,
            location: "",
            // productGuideId: "",
            productGuideNetworkList: [{ orderNums: 1, wifiName: "", wifiPassword: "" }],
            description: "",
          },
        ],
        visitorWifiList: [],
        networkCatalogList: [],
        productId: "",
        orderNums: 0, // 정렬순서(가중치, desc)
        isDisplayed: true, //공개/비공개 여부
      },
    };
    return productGuidePost;
  }, [productGuideType]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  // 이용안내 리스트 가져오기 - 건물 select id 가져오면서 호출 (guideBuildingPrimaryId)
  const getProductGuideListApi = useCallback(
    async (productId: number, buildingId: number) => {
      const queryData = {
        buildingId,
        isDisplayed: false,
      };

      const guideParamData: ProductGuideListRequest = {
        productId,
        query: queryData,
      };

      const response = await getProductGuideList(guideParamData);

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content.sort((a, b) => Number(b?.orderNums) - Number(a?.orderNums));
        setGuideList(result);
      }
    },
    [getProductGuideList],
  );

  // 건물 리스트 select setState
  const guideBuildingPrimaryId = useCallback(
    (buildings: Array<BuildingModel>) => {
      // 대표인 건물 찾기 (건물 내 층/호실에 isPrimary 값이 있다)

      let isPrimaryId = "";

      buildings?.forEach((building: BuildingModel) => {
        const floors = building.buildingFloorList; // 건물 층 리스트
        floors?.forEach((floor) => {
          const rooms = floor.buildingRoomList; // 건물 층 안에 호실 리스트
          const buildingId = building.id;

          // isPrimary 여부 확인
          const isPrimary = rooms?.some((room) => room.isPrimary === true);
          if (isPrimary) {
            setIsBuildingPrimaryId(String(buildingId));
            isPrimaryId = String(buildingId);
            return isPrimaryId;
          } else {
            // isPrimary 가 없으면 첫번째 빌딩 노출
            const firstBuildingId = buildings[0].id;
            setIsBuildingPrimaryId(String(firstBuildingId));
            isPrimaryId = String(firstBuildingId);
            return isPrimaryId;
          }
        });
      });
      // url에 builidngId 가 있으면 setValue - form 영역 진입시 선택한 건물 유지 필요
      if (queryParams.buildingId) {
        setIsBuildingPrimaryId(String(queryParams.buildingId));
      }
    },
    [queryParams.buildingId],
  );

  //
  //
  //  유효성 확인 후 저장 진행
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    // 항목명
    register(`productGuide.subject`, {
      required: { value: true, message: requiredMessage },
    });

    //  가중치
    register("productGuide.orderNums", {
      validate: {
        required: (value) => {
          let result = true;
          let message = "";

          if (value === "" || value === 0) {
            result = false;
            message = requiredMessage;
          }

          return result || message;
        },
      },
    });

    // +항목추가 / 주차 관련 안내 추가 일때 editor 유효성 검사
    if (
      watch("productGuide.productGuideType") === ProductGuideTypes.ETC2 ||
      watch("productGuide.productGuideType") === ProductGuideTypes.PARKINGLOT2
    ) {
      // 상세내용
      register("productGuide.description", {
        validate: {
          required: (value) => {
            let result = true;
            let message = "";

            if (!value || value === " ") {
              result = false;
              message = requiredMessage;
            }

            return result || message;
          },
        },
      });
    }
    // +네트워크 연결 추가 시 유효성 검사
    else {
      // 네트워크 연결 유효성은 FreeInternetSection / FreeInternetNetwork 에서 진행
    }
  }, [register, watch]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);
  //

  // 공간상품 상세조회 후 대표건물 select 지정
  const fetchProduct = useCallback(
    async (productId: number) => {
      setProduct(null);
      // const { data } = await getProduct({ productId });
      const buildings = productDetail?.buildingList;
      guideBuildingPrimaryId(buildings || []);
      setProduct(productDetail);
    },
    [guideBuildingPrimaryId, productDetail],
  );

  // 공간상품 이용안내 상세 조회 후 form setValue
  const fetchProductGuideApi = useCallback(
    async (id: string) => {
      const response = await fetchProductGuide({ guideId: id, isOpened: false });

      if (response.status >= 200 && response.status <= 299) {
        const guideDetail: NewProductGuide = response.data.data.content;

        if (guideDetail) {
          // setValue("productGuide", guideDetail);

          Object.entries(guideDetail).forEach(([name, value]) => {
            switch (name) {
              case "buildingId":
                setValue("productGuide.buildingId", value);
                break;
              case "description":
                setValue("productGuide.description", value);
                break;
              case "id":
                setValue("productGuide.id", value);
                break;
              case "orderNums":
                setValue("productGuide.orderNums", value);
                break;
              case "subject":
                setValue("productGuide.subject", value);
                break;

              case "isDisplayed":
                setValue("productGuide.isDisplayed", value);
                break;
              case "productId":
                setValue("productGuide.productId", value);
                break;
              case "networkCatalogList": {
                // id 순번대로 sort
                const sortedNetworkCatalogList = value.sort(
                  (a: ProductGuideNetworkCatalog, b: ProductGuideNetworkCatalog) => Number(a.id) - Number(b.id),
                );

                // 통합 array 에서 고객용 분리
                const customerWifi = sortedNetworkCatalogList.filter((item: ProductGuideNetworkCatalog) => item.isOpened === false);

                // 통합 array 에서 방문자용 분리
                const visitorWifi = sortedNetworkCatalogList.filter((item: ProductGuideNetworkCatalog) => item.isOpened === true);

                setValue("productGuide.customerWifiList", customerWifi);
                setValue("productGuide.visitorWifiList", visitorWifi);
              }
            }
          });

          if (guideDetail.networkCatalogList) {
            const customerWifi = guideDetail.networkCatalogList.filter((item) => item.isOpened === false);

            const visitorWifi = guideDetail.networkCatalogList.filter((item) => item.isOpened === true);

            setValue("productGuide.customerWifiList", customerWifi);
            setValue("productGuide.visitorWifiList", visitorWifi);
          }
        }
      }
    },

    [fetchProductGuide, setValue],
  );

  //
  //
  // 공간상품 상세조회
  useEffect(() => {
    fetchProduct(Number(productId));

    // guideId 가 있으면 (수정일 때) 호출
    if (guideId) {
      fetchProductGuideApi(String(guideId));
    }
  }, [productId, fetchProduct, guideId, fetchProductGuideApi]);

  // 공간상품 이용안내 리스트 호출
  useEffect(() => {
    if (productId && isBuildingPrimaryId) getProductGuideListApi(Number(productId), Number(isBuildingPrimaryId));
  }, [getProductGuideListApi, isBuildingPrimaryId, productId]);

  //저장 모달 확인 클릭시 등록/수정
  const onClickConfirmModal = useCallback(
    async (data: ProductGuideUpdateForm) => {
      if (guideId) {
        const response = await editProductGuide({
          productId: Number(productId),
          guides: data,
        });
        if (response.status >= 200 && response.status <= 299) {
          console.log(response);
          setConfirmModal({ isOpen: false });
          navigate(`${PagePath.product.detail.replace(":id", String(productId))}?tab=guide&buildingId=${data.productGuide?.buildingId}`);
          console.log(`hi`);
        }
      } else {
        const response = await postProductGuide({
          productId: Number(productId),
          guides: data,
        });
        if (response.status >= 200 && response.status <= 299) {
          console.log(response);
          setConfirmModal({ isOpen: false });
          navigate(`${PagePath.product.detail.replace(":id", String(productId))}?tab=guide&buildingId=${data.productGuide?.buildingId}`);
          console.log(`hi`);
        }
      }
    },
    [editProductGuide, guideId, navigate, postProductGuide, productId],
  );

  //

  //
  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(
    (data: ProductGuideUpdateForm, e?: any) => {
      e.preventDefault();

      const customerWifiList = data.productGuide?.customerWifiList;
      const visitorWifiList = data.productGuide?.visitorWifiList;
      let mergedNetworks = [...(customerWifiList || []), ...(visitorWifiList || [])];
      let convertData: ProductGuideUpdateForm = {};

      // 구버전에 있는 customerWifiList / visitorWifiList 를 제외하고 setValue
      if (data.productGuide?.productGuideType === ProductGuideTypes.GUIDE_WIFI) {
        convertData = {
          productGuide: {
            ...data.productGuide,
            networkCatalogList: mergedNetworks,
            customerWifiList: [],
            visitorWifiList: [],
            buildingId: String(isBuildingPrimaryId),
            productId: String(productId),
          },
        };
      } else {
        convertData = {
          productGuide: {
            ...data.productGuide,
            networkCatalogList: [],
            customerWifiList: [],
            visitorWifiList: [],
            buildingId: String(isBuildingPrimaryId),
            productId: String(productId),
          },
        };
      }
      setSubmitData(convertData);
      console.log("create", convertData);
      setConfirmModal({ isOpen: true, message: "저장하시겠습니까?" });
    },
    [isBuildingPrimaryId, productId],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);

    return false;
  }, []);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <form onSubmit={handleSubmit(onSubmit, onError)} id="productForm">
            <div className="contents-container__wrap-article">
              <article className="contents-container__1200">
                <section className="contents-container__grid contents-container__1200">
                  <div className="contents-container__grid-index">
                    <p>URL</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <div className="font14">
                        <a
                          target={"_blank"}
                          className="text-underline"
                          href={`${courtUrl}/front/court/product/${productId}/public/guides`}
                          rel="noreferrer"
                        >
                          {`${courtUrl}/front/court/product/${productId}/public/guides`}
                          <span className="ic-target-blank"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid contents-container__1200">
                  <div className="contents-container__grid-index flex-start">
                    <p className="mr4">문의채널</p>
                    <BaseTooltip touchIcon={"QUESTION"} className="mt1" size={16} tooltip="전시정보 탭에서 수정할수 있습니다." />
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <div className="font14">
                        <a className="text-underline" target={"_blank"} href={`${product?.vocUrl}`} rel="noreferrer">
                          {product?.vocUrl}
                          <span className="ic-target-blank"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>

                {buildingOptions?.length === 0 ? (
                  <section className="contents-container__grid contents-container__1200 mb30">
                    <div className="contents-container__grid-index">
                      <p>건물</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-row flex-center-start">
                        <div className="minmax140">
                          <p>데이터가 없습니다.</p>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  <>
                    <section className="contents-container__grid contents-container__1200 mb30 ">
                      <div className="contents-container__grid-index">
                        <p>건물</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="flex-row flex-center-start">
                          <div className="minmax140">
                            <BaseSelect
                              className="minmax200"
                              isDisabled={(buildingOptions?.length === 1 && true) || !!queryParams.productGuideType}
                              value={isBuildingPrimaryId}
                              stateOptions={buildingOptions || []}
                              setStateValue={(value: string) => {
                                const isProductGuideType = queryParams.productGuideType;

                                const guideFormPath = `${PagePath.product.form}?id=${productId}&tab=guide&buildingId=${value}${
                                  !!isProductGuideType //productGuideType 타입이 있으면 url에 추가표시
                                    ? `&productGuideType=${isProductGuideType}`
                                    : ""
                                }`;
                                navigate(guideFormPath);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section>
                      {!queryParams.productGuideType ? (
                        <ProductGuideTypeList guideList={guideList} buildingId={isBuildingPrimaryId} productId={productId || 0} />
                      ) : (
                        //건물 select box & 이용안내 talbe list & 수정버튼 컴포넌트
                        // +항목추가, +네트워크 추가, +주차장 추가 영역
                        <GuideUploadForms
                          productGuideType={productGuideType}
                          control={control}
                          guideId={guideId || ""}
                          setValue={setValue}
                          getValues={getValues}
                          register={register}
                          errors={errors}
                        />
                      )}
                    </section>
                  </>
                )}
              </article>
            </div>
          </form>
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => {
              onClickConfirmModal(submitData || {});
            }}
            onClose={() => setConfirmModal({ isOpen: false })}
            title={confirmModal.message}
          >
            {/* <p>{confirmModal.message}</p> */}
          </BaseModal>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        {queryParams.productGuideType && (
          <div className="right-area">
            <BaseButton title="저장" className="size-large" type="submit" form="productForm" />
          </div>
        )}
      </div>
    </>
  );
};

export default GuideForm;
