import { ContractOperationProductManage } from "src/api/contract/contract-types";
import { BaseButton, BaseToggle } from "src/components";
import Link from "src/components/PartnerLink";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

type Props = {
  detailData: any;
  productManage: ContractOperationProductManage;
  setData: (data: ContractOperationProductManage) => void;
  goToList: string;
  isAuthority?: boolean;
};

const VisitorAccessSection = ({ detailData, productManage, setData, goToList, isAuthority }: Props) => {
  const { fetchAuthorityReadonly } = usePartnerAuthority();
  console.log("productManage", productManage);
  console.log("detailData", detailData);
  return (
    <div className="contents-container__1200">
      <div className="contents-container__sub-title ">
        <div className="minmax180 pb4">
          <h2 className="font18">방문자 관리</h2>
        </div>
      </div>
      <div className="contents-contract-user">
        <div className="text-center px60 py30">
          <div className="mb10">
            <span className="font14 text-primary3">방문자초대 기능 제공</span>
          </div>
          <div className="mb17">
            <BaseToggle
              checked={productManage.isUseVisitorInvite || false}
              onChange={(checked: boolean) => {
                if (isAuthority) {
                  const calendarType = productManage.calendarType !== "" ? productManage.calendarType : "NONE";

                  const newProductManage = { ...productManage, isUseVisitorInvite: checked, calendarType };
                  setData(newProductManage);
                } else {
                  fetchAuthorityReadonly(true);
                }
              }}
              // className={`${detailData?.product?.productType! === "TIME_COURT" && "not-available"}`}
              // disabled={detailData?.product?.productType! === "TIME_COURT"}
            />
          </div>
          <div className="flex-center-center">
            <Link to={goToList} target="_blank">
              <BaseButton title="방문자 초대" className="color-white icon-invite" />
            </Link>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorAccessSection;
