import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { DeskSection, MeetingRoomSection, ParkingSection, RefreshRoomSection } from "./components";
import MetaTag from "src/components/layout/MetaTag";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

/* 
  건물 > 상세 > 공용공간
 */
const FacilityDetail = () => {
  // 로딩바
  //

  const location = useLocation();
  const navigate = useNavigate();

  // path variable 에서 buildingId 추출
  const buildingId = useMemo(() => {
    const paths = location.pathname.split("/");
    const pathBuildingId = paths[paths.length - 1];
    return Number(pathBuildingId);
  }, [location]);

  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  const { isAuthority } = usePartnerAuthority();

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  useEffect(() => {
    if (buildingId) {
      async function fetchBuilding(buildingId: number) {
        //
        const { data } = await getBuilding({ buildingId });
        setBuilding(data?.data?.content?.building || null);
        //
      }
      fetchBuilding(buildingId);
    }
  }, [buildingId, getBuilding]);

  // title 에 건물명 바인딩
  let buildingName;
  if (buildingId && building?.buildingName) {
    buildingName = building?.buildingName;
  }

  return (
    <>
      <MetaTag subTitle={buildingName} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {building && (
            <>
              <article className="contents-container__wrap-article">
                <div className="contents-container__sub-title">
                  <h2>주차</h2>
                </div>
                <ParkingSection building={building} />
              </article>
              <article className="contents-container__wrap-article">
                <div className="contents-container__sub-title">
                  <h2>회의실</h2>
                </div>
                <MeetingRoomSection building={building} />
              </article>
              <article className="contents-container__wrap-article">
                <div className="contents-container__sub-title">
                  <h2>좌석</h2>
                </div>
                <DeskSection building={building} />
              </article>
              <article className="contents-container__wrap-article">
                <div className="contents-container__sub-title">
                  <h2>편의시설</h2>
                </div>
                <RefreshRoomSection building={building} />
              </article>
            </>
          )}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          {isAuthority("BUILDING", "w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.building.form}?id=${buildingId}&tab=facility`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FacilityDetail;
