import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getManageBuildingAsync } from "src/api/building/building-api";
import { DetailTotalData } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { ContractDeviceModel, IotDeviceQueryParams } from "src/api/iot/iot-types";
import { Order } from "src/api/public-types";
import { BaseButton, BaseInput } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import IotDevice from "src/pages/building/forms/iotDevice/IotDevice";
import BuildingDeviceColumns from "src/pages/building/forms/iotDevice/columns/BuildingDeviceColumns";
import IotCommonTableModal from "src/pages/building/forms/iotDevice/components/IotCommonTableModal";
import IotDeviceDetail from "src/pages/building/forms/iotDevice/components/IotDeviceDetail";
import { useIotDevice } from "src/pages/building/forms/iotDevice/hooks/useIotDevice";

const queryData: IotDeviceQueryParams = { page: 0, size: 20, sort: { orders: [{ property: "internalDisplayName", direction: "ASC" }] } };

type Props = {
  detail: DetailTotalData;
};
//
const ContractIotDevice = ({ detail }: Props) => {
  const { isAuthority } = usePartnerAuthority();
  const location = useLocation();

  const [showDevices, setShowDevices] = useState(false);
  const query = useMemo(() => qs.parse(location.search, { allowDots: true, ignoreQueryPrefix: true }), [location]);

  const [params, setParams] = useState<IotDeviceQueryParams>(queryData);

  const [keyword, setKeyword] = useState("");

  const [buildingIds, setBuildingIds] = useState<number[]>([]);

  // iot 탭 목록에서만 사용할 sorting 데이터
  const [deviceOrders, setDeviceOrders] = useState<Order[]>([{ property: "internalDisplayName", direction: "ASC" }]);

  const contractManageId = useMemo(() => detail.contract?.contractManageId, [detail.contract?.contractManageId]);

  const contractApplyNumber = useMemo(() => detail.contractManage?.contractApplyNumber, [detail.contractManage?.contractApplyNumber]);

  // 신청계약 iot 탭 > 탭 상세 목록
  const { iotDevices, addDevices, deleteDevice } = useIotDevice({ contractManageId, sort: { orders: deviceOrders } }, { executeApi: true });

  // 계약 iot기기 추가 모달 목록 [계약에 연결된 상품의 건물에 등록된 iot]
  const { iotDevices: buildingDevices, pageMeta } = useIotDevice(params, { executeApi: showDevices });

  // 계약 건물 운영정보 조회
  const { executeAsync: getManageBuilding } = useApiOperation(getManageBuildingAsync);

  useEffect(() => {
    (async () => {
      if (detail.contractManage?.contractManageId) {
        const { data, status } = await getManageBuilding({ contractManageId: String(detail.contractManage?.contractManageId) });
        if (status >= 200 && status < 300) {
          const buildingIds = data.data.buildingManageList.map((item) => item.buildingId);
          console.log("buildingIds", buildingIds);
          setBuildingIds(buildingIds);
        }
      }
    })();
  }, [detail.contractManage?.contractManageId]);

  return (
    <>
      {/*  */}
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-article pt0">
            <div className="contents-container__sub-title pt30 pb10">
              <div className="flex-center-end w-100 ">
                {isAuthority("CONTRACT", "w") && (
                  <>
                    {!query.tabType && (
                      <BaseButton
                        title="+ 기기 추가"
                        onClick={() => {
                          setShowDevices(true);
                          setParams({
                            ...params,
                            buildingId: buildingIds.join(",") || "",
                          });
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            {(!query.tabType || query.tabType === "") && ( //
              <IotDevice
                deviceList={iotDevices}
                deleteDevice={deleteDevice}
                isAuthority={isAuthority("CONTRACT", "d")}
                orders={deviceOrders}
                setOrders={setDeviceOrders}
              />
            )}
          </div>
        </div>
      </div>

      {/*  */}

      {iotDevices.length > 0 && query.tabType === "detail" && (
        <IotDeviceDetail
          deviceType="contract"
          detail={iotDevices.find((device: ContractDeviceModel) => Number(device.id) === Number(query.tabId))}
          contractApplyNumber={contractApplyNumber || ""}
        />
      )}
      {showDevices && (
        <IotCommonTableModal
          columns={BuildingDeviceColumns.filter((item) => item.Header !== "id" && item.Header !== "삭제")}
          // data={productDevices}
          data={buildingDevices}
          title="기기선택"
          checkboxType={"checkbox"}
          pageMeta={pageMeta}
          goToPage={(page) => setParams({ ...params, page })}
          currentSize={(size: number) => setParams({ ...params, size })}
          orders={params.sort?.orders}
          setOrders={(orders?: Order[]) => {
            setParams({ ...params, sort: { orders } });
          }}
          onClick={function (list: any[]): void {
            if (list.length > 0) {
              const data = {
                contractManageId: Number(contractManageId),
                buildingDeviceData: list.map((item) => {
                  return {
                    buildingDeviceId: item.id,
                    internalDisplayName: item.internalDisplayName,
                    buildingName: item.building.buildingName,
                  };
                }),
                // buildingDeviceIds: list.map((item) => item.id),
              };
              addDevices(data);
            }
          }}
          onClose={function (): void {
            setShowDevices(false);
            setParams(queryData);
            setKeyword("");
          }}
        >
          <div className="right-area">
            <div className="d-flex">
              <BaseInput
                type="text"
                value={keyword}
                onChange={(keyword: string) => setKeyword(keyword)}
                onKeyUp={() => {
                  setParams({ ...params, page: 0, search001: keyword });
                }}
                placeholder="검색어를 입력해주세요"
                onSearchClick={() => {
                  setParams({ ...params, page: 0, search001: keyword });
                }}
              />
            </div>
          </div>
        </IotCommonTableModal>
      )}
      {/* 목록으로 */}
      <div>
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <GoToListButton />
          </div>
          <div className="right-area"></div>
        </div>
      </div>
    </>
  );
};

export default ContractIotDevice;
